import React, {useCallback, useState} from 'react';
import AppWrapper from "../../components/app/AppWrapper";
import {Divider, IconButton, InputAdornment, TextField} from "@mui/material";
import cx from "classnames"
import {AppButton} from "../../components/ui/AppButton";
import {useI18n} from "../../i18n/I18nContext";
import {useAuthContext} from "../../api/auth/AuthContext";
import {useFormik} from "formik";
import {object, string} from "yup";
import {Link, useHistory} from "react-router-dom";
import {Routes} from "../../constants/Routes";
import Cookies from "js-cookie";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {changeViewMode} from "../../reducers/appReducer";
import {ViewMode} from "../../api/dto/AppDTO";
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import {Visibility, VisibilityOff} from "@mui/icons-material";
import AuthWelcome from "../../components/auth/AuthWelcome";
import {showError} from "../../utils/NotificationUtils";

const validationSchema = (translate: any) => object({
    email: string().email(translate("EMAIL_ERROR")).required(translate("REQUIRED")),
    password: string().min(6,translate("PASSWORD_LENGTH")).required(translate("REQUIRED")),
});

interface Props {
    readonly classes: any;
}


export function AuthLoginContainer({classes}: Props) {
    const {translate} = useI18n();
    const {AuthApi} = useAuthContext();
    const history = useHistory();
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const submitHandler = useCallback((values) => {
            setSubmitting(true);
            AuthApi.signIn({data: values}).then(res => {
                setSubmitting(false);
                if (res.status === "OK") {
                    Cookies.set("token", res.payload.token);
                    toast.success(translate("SUCCESSFUL_LOGIN"));
                    dispatch(changeViewMode({ viewMode: ViewMode.Full }));
                    history.replace({pathname: "/"})
                } else if (res.status === "WRONG_CREDENTIALS") {
                    toast.error(translate("WRONG_CREDENTIALS"));
                } else {
                    toast.error(res.errors);
                }
            }).catch(err => {
                showError(err);
                setSubmitting(false);

            })

    }, [AuthApi, history, dispatch, translate]);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema(translate),
        onSubmit: (values) => submitHandler(values),
    });


    return (
        <AppWrapper>
            <div className={classes.root}>
                <div className={classes.signUpBody}>
                    <AuthWelcome classes={classes} />
                    <div className={cx(classes.signUpRight, classes.signUpSide)}>
                        <div>
                            <AppButton className={classes.oAuthBtn}
                                       background="#5086EC"
                                       variant="contained">
                                <GoogleIcon/>
                                {translate("SIGN_IN")}{" "}{translate("WITH_GOOGLE")}
                            </AppButton>
                            <AppButton className={classes.oAuthBtn}
                                       background="#405A94"
                                       variant="contained">
                                <FacebookIcon/>{translate("SIGN_IN")}{" "}
                                {translate("WITH_FACEBOOK")}
                            </AppButton>
                        </div>
                        <Divider className={classes.divider}>{translate("SIGNIN_WITH_EMAIL")}</Divider>
                        <form onSubmit={formik.handleSubmit}>
                            <TextField fullWidth variant="outlined"
                                       value={formik.values.email}
                                       onChange={formik.handleChange}
                                       error={formik.touched.email && Boolean(formik.errors.email)}
                                       helperText={formik.touched.email && formik.errors.email}
                                       type="text"
                                       label={translate("EMAIL")}
                                       name="email"
                                       id="email"
                                       className={classes.email}
                            />
                            <TextField fullWidth variant="outlined"
                                       value={formik.values.password}
                                       onChange={formik.handleChange}
                                       error={formik.touched.password && Boolean(formik.errors.password)}
                                       helperText={formik.touched.password && formik.errors.password}
                                       type={showPassword ? "text" : "password"}
                                       label={translate("PASSWORD")}
                                       name="password"
                                       id="password"
                                       className={classes.email}
                                       InputProps={{
                                           endAdornment: <InputAdornment position="end">
                                               <IconButton
                                                   aria-label="toggle password visibility"
                                                   onClick={() => {setShowPassword(prev => !prev)}}
                                                   edge="end"
                                               >
                                                   {showPassword ? <VisibilityOff /> : <Visibility />}
                                               </IconButton>
                                           </InputAdornment>,
                                       }}
                            />
                            <AppButton type="submit" className={classes.submitBtn} disabled={submitting} loading={submitting}
                                       variant="contained">{translate("SIGN_IN")}</AppButton>
                            <p className={classes.footerInfos}>{translate("SIGN_IN_NO_ACCOUNT")}{" "}<Link
                                to={Routes.AuthSignUp}>{translate("SIGN_IN_NO_ACCOUNT_CREATE_ONE")}</Link></p>
                        </form>
                    </div>
                </div>
            </div>
        </AppWrapper>
    );
}

