import React from "react";
import { Route, Switch } from "react-router";

import { Routes } from "../../constants/Routes";
import {AuthLoginContainer} from "./AuthLoginContainer";
import {AuthSignUpContainer} from "./AuthSignUpContainer";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        padding: `${theme.typography.pxToRem(78)} ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(20)}`,
        justifyContent: "center",
        //fontFamily: "Lato, sans-serif",
        [theme.breakpoints.down(500)]: {
            paddingTop: theme.typography.pxToRem(20),
        }
    },
    signUpBody: {
        background: theme.palette.common.white,
        padding: `${theme.typography.pxToRem(32)} 0`,
        display: "flex",
        boxShadow: theme.shadows[1],
        maxWidth: theme.typography.pxToRem(792),
        borderRadius: theme.typography.pxToRem(16),
        width: "100%"
    },
    signUpLeft: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        "& h1": {
            marginBottom: theme.typography.pxToRem(33),
        },
        [theme.breakpoints.down(700)]: {
            "& h1": {
                fontSize: theme.typography.pxToRem(33),
            }

        },
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    signUpRight: {},
    signUpSide: {
        flex: "1 1",
        width:"50%",
        padding: ` 0 ${theme.typography.pxToRem(32)}`,
        [theme.breakpoints.down(700)]: {
            padding: ` 0 ${theme.typography.pxToRem(20)}`,
        }
    },
    title: {
        fontSize: theme.typography.pxToRem(42),
        lineHeight: theme.typography.pxToRem(36),
        fontWeight: 800,
        color: theme.palette.text.secondary,
        wordBreak: "break-word",
        "& span": {
            fontFamily: "Amarillo, sans-serif !important" as any,
            fontSize: theme.typography.pxToRem(32),
            fontWeight: 400,
        }
    },
    submitBtn: {
        width: "100%",
        marginBottom: `${theme.typography.pxToRem(12)}!important` as any,
    },
    oAuthBtn: {
        marginBottom: `${theme.typography.pxToRem(12)}!important` as any,
        width: "100%",
    },
    divider: {
        color: theme.palette.grey[700],
        marginBottom: `${theme.typography.pxToRem(24)}!important` as any,
    },
    inputBox: {
        display: "flex",
        marginBottom: theme.typography.pxToRem(16),
        "& .MuiFormControl-root:first-child": {
            marginRight: theme.typography.pxToRem(16)
        },
        [theme.breakpoints.down(400)]: {
            flexDirection: "column",
            gap: 16
        }
    },
    email: {
        marginBottom: `${theme.typography.pxToRem(16)}!important` as any,
    },
    privacyPolicy: {
        marginBottom: theme.typography.pxToRem(24),
    },
    footerInfos: {
        //fontFamily: "Roboto",
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(12),
        textAlign: "center",
    },
    listItem: {
        display: "flex",
        alignItems: "center",
        color: theme.palette.text.primary,
        marginBottom: theme.typography.pxToRem(17),
        "& svg": {
            marginRight: theme.typography.pxToRem(10),
        }
    }
}));

export default function AuthContainer() {
    const classes = useStyles();
    return (
        <Switch>
            <Route exact={true} render={() => <AuthLoginContainer classes={classes}  />} path={Routes.AuthLogin} />
            <Route exact={true} render={() => <AuthSignUpContainer classes={classes} />} path={Routes.AuthSignUp} />
        </Switch>
    );
}
