import React, {useEffect, useState} from "react";
import {useActionContext} from "../../api/action/ActionContext";
import {makeStyles} from "@mui/styles";
import {CodeEditor} from "../ui/CodeEditor";
import {AppButton} from "../ui/AppButton";
import {StepsType} from "./workflowEditor";
import {Loader} from "../ui/Loader";

const useStyles = makeStyles(() => ({
    root: {
        padding: `8px 0`,
    },
}));

interface Props {
    readonly data: any;
    readonly handleChange: (name: any, value: any) => void;
    readonly onDone: (type: StepsType) => void,
    readonly stepType: StepsType,
}



function WorkflowActionSetup({data, handleChange,onDone,stepType}: Props) {

    const {ActionApi} = useActionContext();

    const [loaded, setLoaded] = useState(false);
    const [changed, setChanged] = useState(false);
    const [source, setSource] = useState<string>("");

    useEffect(() => {
        setSource(data?.mappingSource);
        ActionApi.getActionDetails(data.eventId).then(() => {
                setLoaded(true)
        }).catch(() => {
            setLoaded(true)
        })
    }, []);


    const classes = useStyles();
    return (
        <div className={classes.root}>
            {loaded ? <>
                <CodeEditor value={source} onChange={(editor, data, value) => {
                    setSource(value);
                    setChanged(true)
                }}/>
                <AppButton sx={{mt: 1}} disabled={!changed} onClick={() => {
                    handleChange("mappingSource", source);
                    if (source) {
                        onDone(stepType)
                    }
                    setChanged(false)
                }}>{!changed ? "No Changes" : "Please save code changes"}</AppButton>
            </> : <Loader />}
        </div>
    );
}

export default WorkflowActionSetup;





// Todo DO NOT DELETE THIS CODE


// import React, {useEffect, useState} from "react";
// import {useActionContext} from "../../api/action/ActionContext";
// import {makeStyles} from "@mui/styles";
// import {useParams} from "react-router";
// import {Loader} from "../ui/Loader";
// import {CustomField} from "../ui/CustomField";
// import {useWorkflowContext} from "../../api/workflow/WorkflowContext";
// import {decamelize} from "../../helpers/StringHelpers";
// import {deepUpdate} from "immupdate";
// import {AppButton} from "../ui/AppButton";
//
// const useStyles = makeStyles(() => ({
//     fieldBox: {
//         padding: `8px 0`,
//     },
// }));
//
// interface Props {
//     readonly data: any;
//     readonly handleChange: (name: any, value: any, id: number) => void;
// }
//
// interface FieldProps {
//     readonly label:string,
//     readonly value:string,
//     readonly parentObject:string | undefined,
//     readonly id:number,
// }
//
//
// function WorkflowActionSetup({data, handleChange}: Props) {
//     const params: any = useParams();
//
//     const {ActionApi} = useActionContext();
//     const {WorkflowApi} = useWorkflowContext();
//     const [testData, setTestData] = useState<any | null>(null);
//     const [loading, setLoading] = useState(true);
//     const [changed, setChanged] = useState(false);
//     const [fields, setFields] = useState<FieldProps[]>([]);
//
//
//     function fieldsArrayToMappingSource(arr: any[]) {
//         const mapObj: any = {};
//
//         arr.forEach((item: any) => {
//             let value = item.value;
//             if (value !== null) {
//                 const arrVal = value?.split("&nbsp;");
//                 const textArr = arrVal.map((x: any) => {
//                     const isSpan = x.indexOf("data-value=") === -1;
//                     if (isSpan) {
//                         if (x.search("{{") !== -1) {
//                             return `${x.replace(/{{[a-z.A-Z._|0-9_]*}}/g, (text: string) => {
//                                 return "${" + `response.${text.replaceAll(/[{|}]/g, "")}` + "}"
//                             })}`
//                         }
//                         return x;
//                     } else {
//                         const firstPart = x.substr(59);
//                         const textLength = firstPart.indexOf(`">`);
//
//                         return "${" + `response.${firstPart.substr(0, textLength)}`.replace('"', "") + "}";
//                     }
//                 });
//                 value = textArr.join(" ")
//             }
//
//             if (item.parentObj === undefined) {
//                 mapObj[item.label] = value
//             } else {
//                 if (mapObj[item.parentObj]) {
//                     mapObj[item.parentObj][item.label] = value
//                 } else {
//                     mapObj[item.parentObj] = {};
//                     mapObj[item.parentObj][item.label] = value
//                 }
//             }
//
//         });
//         return mapObj
//     }
//
//     const mappingSource = (arr: any[]) => {
//         const mappedObj = fieldsArrayToMappingSource(arr);
//
//         let source = `
//         const  response = bundle.rawData['trigger_${params.id}'];
//         const inputData = ${JSON.stringify(mappedObj).replace(/:"[^,|;|]*/gm, (text) => {
//             return text.replaceAll('"', '`')
//         })};
//         return inputData ? inputData : response
//     `;
//         return source
//
//     };
//
//     const objToFieldsArray = (obj: any) => {
//         let result: any[] = [];
//         let count = 0;
//         function f(object: any, parentObj?: any) {
//             let keys = Object.keys(object);
//             for (let i = 0; i < keys.length; i++) {
//                 if (typeof object[keys[i]] !== "object") {
//                     count++;
//                     result.push({
//                         label: keys[i],
//                         value: object[keys[i]].toString(),
//                         parentObj: parentObj,
//                         id: count
//                     });
//                 } else {
//                     if (Array.isArray(object[keys[i]])) {
//                         console.log("array")
//                     } else {
//                         count++;
//                         f(object[keys[i]], parentObj !== undefined ? `${parentObj}.${keys[i]}` : keys[i])
//                     }
//                 }
//             }
//         }
//
//         f(obj);
//         return result
//     };
//
//
//     const onFieldsUpdate = (id: number, value: string | null) => {
//         setFields(prev => {
//             return deepUpdate(prev)
//                 //@ts-ignore
//                 .at(fields.findIndex((x) => x.id === id))
//                 .abortIfUndef()
//                 //@ts-ignore
//                 .at("value")
//                 //@ts-ignore
//                 .modify(() => value)
//         });
//         setChanged(true)
//     };
//
//
//     useEffect(() => {
//         WorkflowApi.testTrigger(params.id).then((r) => {
//             setTestData(r.payload[0]);
//         });
//         ActionApi.getActionDetails(data.eventId).then((res) => {
//             let apiEndpoint = res.payload.apiEndpoint;
//             if (apiEndpoint.method === "GET") {
//                 setLoading(false);
//             } else {
//                 const source = apiEndpoint.source
//                     ?.replace(/(\\n|\\r|\r|\n)/gm, "")
//                     .replace(/\s+/g, " ");
//                 const sourceBody = source.substr(0, source.search("return"));
//                 let object = eval(`
//                 function returnObject() {
//                     ${sourceBody
//                     .replaceAll(/bundle.[a-zA-Z].[^,}]*/gm, '"' + "$&" + '"')
//                     .replaceAll("bundle.inputData.", "{{")
//                     .replaceAll(/{{[a-z.A-Z]*/g, " " + "$&" + "}} ")
//                 }
//
//                     let newObject = {}
//                     options.body = Object.keys(options.body).map(item => {
//                             newObject[item] = options.body[item]
//                     })
//                      return newObject
//                   }
//                 returnObject()
//         `);
//                 setFields(objToFieldsArray(object));
//                 setLoading(false);
//             }
//         });
//     }, []);
//
//
//     const classes = useStyles();
//     return (
//         <div>
//             {!loading ? (
//                 <div>
//                     {fields.length > 0 && fields?.map((x, index) => {
//                         return (
//                             <div className={classes.fieldBox} key={index}>
//                                 <CustomField
//                                     value={x?.value?.replace(/{{[a-z.A-Z._|0-9_]*}}/gm, (text:string) => {
//                                         let v = text.replace(/[{|}]/g, "");
//                                         return `<span
//                                 contenteditable="false"
//                                 class="inputTag"
//                                 data-value="${v}">
//                                     <strong>${v} </strong>
//                                     ${testData && eval(`testData${v.split(".").map(x => `["${x}"]`).join("")}`) || "no data"}
//                             </span>`;
//                                     })}
//                                     onFieldsUpdate={(id: number, value: string | null) => {
//                                         if (value !== null) {
//                                             onFieldsUpdate(id, value)
//                                         }
//                                     }}
//                                     testData={testData}
//                                     id={x.id}
//                                     /*@ts-ignore*/
//                                     label={decamelize(`${x.parentObj !== undefined ? x.parentObj : ""}  ${x.label}`, " ")}
//                                 />
//                             </div>
//                         )
//                     })}
//                 </div>
//             ) : (
//                 <Loader/>
//             )}
//
//             <AppButton sx={{mb: 3}} onClick={() => {
//                 mappingSource(fields);
//                 handleChange("mappingSource", mappingSource(fields), data.id);
//                 setChanged(false)
//             }} disabled={!changed}>Save</AppButton>
//         </div>
//     );
// }
//
// export default WorkflowActionSetup;
//
//
//


