import {BaseApi, Options} from "../BaseApi";

export class IntegrationsApi extends BaseApi{
    public getIntegrationsList(): Promise<any> {
        return this.get("/integration/list")
    }

    public getIntegrationById(options?: Options): Promise<any> {
        return this.get("integration/:id",options)
    }
}