import React, {useCallback, useEffect, useState} from 'react';
import {Field} from "formik";
import {useI18n} from "../../../i18n/I18nContext";
import {AppAutoComplete} from "../../ui/AppAutoComplete";
import {Box, IconButton, TextField} from "@mui/material";
import {WorkflowEditorTriggerDTO} from "../../../api/workflow/WorkflowDTO";
import {useIntegrationsContext} from "../../../api/integrations/IntegrationsContext";
import {useConnectionsContext} from "../../../api/connections/ConnectionsContext";
import {showError} from "../../../utils/NotificationUtils";
import AddIcon from '@mui/icons-material/Add';
import {AppButton} from "../../ui/AppButton";
import {toast} from "react-toastify";
import Cookies from "js-cookie";

interface Props {
    readonly data: WorkflowEditorTriggerDTO
    readonly onSetFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

export function WorkflowAccountSelectV2({data, onSetFieldValue}: Props) {
    const {translate} = useI18n();
    const [loading, setLoading] = useState<boolean>(true);
    const [update, setUpdate] = useState<number>(0);
    const [list, setList] = useState<any>([]);
    const {ConnectionsApi} = useConnectionsContext();
    let createConnectionWindow: Window | null;

    useEffect(() => {
        ConnectionsApi.getConnectionsList(data.appId).then(res => {
            let data: any = [];
            res.payload?.list?.map((x: any) => data.push({label: x.name, value: x.id}));
            setList(data);
            setLoading(false)
        }).catch(err => {
            showError(err);
            setLoading(false)
        });
    }, [data, ConnectionsApi, update]);

    useEffect(() => {
        const windowGetMessage = (event: any) => {
            if (event.data === "cancel") {
                // @ts-ignore
                createConnectionWindow.close();
                console.log(event)
            } else if (event.data === "success") {
                // @ts-ignore
                toast.success("Connection successfully created");
                setUpdate(prev => prev + 1)
            }
        };

        window.addEventListener("message", windowGetMessage);

        return () => {
            window.removeEventListener("message", windowGetMessage)
        }
    }, []);

    const handleSignIn = useCallback(() => {
        createConnectionWindow = window.open(
            "https://admin-connector.kpi.com/connection/"
            + data.appId
            + `?jwt_token=${Cookies.get("token")}`,
            "integration",
            "left=100,top=100,width=500,height=500"
        );

    }, [])

    return (
        <Box sx={{my: 2}}>
            <AppAutoComplete
                label={translate("CHOOSE_TRIGGER_EVENT")}
                loadingOptions={loading}
                options={list}
                value={data?.accountId}
                required
                onValueChange={(value) => onSetFieldValue("accountId", value)}
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        fullWidth
                        name="eventId"
                        variant="outlined"
                    />
                )}
            />

            <AppButton
                fullWidth={true}
                sx={{mt: 2}}
                variant="contained"
                background="#000000"
                onClick={handleSignIn}
            >
                + Connect new account
            </AppButton>
        </Box>
    );
}

