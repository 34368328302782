import React from 'react';
import { makeStyles } from "@mui/styles";
import {CSSProperties} from "@mui/styles";
import {Theme} from "@mui/material";
import cx from "classnames"


const useStyle = makeStyles((theme: Theme) => ({
    root: {
        fill: "none",

        fontSize: theme.typography.pxToRem(17),
    },
}));

interface Props {
    readonly className?: string;
    readonly style?: CSSProperties;
}

export function Logo({ className, style }: Props): JSX.Element {

    const classes = useStyle();

    return (
        <svg
            className={cx(classes.root, className)} style={style}
            fill="none"
            width={81}
            height={40}
        >
            <path
                d="M27.673 2.403V33h-3.696c-.546 0-1.008-.084-1.386-.252-.364-.182-.728-.49-1.092-.924L7.072 13.575c.056.546.091 1.078.105 1.596.028.504.042.98.042 1.428V33H.961V2.403h3.738c.308 0 .567.014.777.042.21.028.399.084.567.168.168.07.329.175.483.315.154.14.329.329.525.567L21.604 21.87a25.415 25.415 0 01-.147-1.701 31.643 31.643 0 01-.042-1.575V2.403h6.258z"
                fill="#3F51B5"
            />
            <path
                d="M39.216 4.356v31.395h2.31c.49 0 .89.154 1.197.462.322.308.483.672.483 1.092v2.499h-9.408V.303h9.408v2.478c0 .434-.16.805-.483 1.113-.308.308-.707.462-1.197.462h-2.31zM66.7 25.083c.168 0 .336.035.504.105.168.056.33.161.483.315l2.814 2.961a11.753 11.753 0 01-4.64 3.654c-1.849.812-4.04 1.218-6.574 1.218-2.324 0-4.41-.392-6.258-1.176-1.834-.798-3.395-1.89-4.683-3.276-1.274-1.4-2.254-3.052-2.94-4.956-.686-1.918-1.029-3.997-1.029-6.237 0-2.282.371-4.375 1.113-6.279.742-1.918 1.785-3.57 3.13-4.956 1.343-1.386 2.953-2.464 4.83-3.234 1.875-.77 3.94-1.155 6.194-1.155 1.148 0 2.226.105 3.234.315 1.022.196 1.974.476 2.856.84a12.783 12.783 0 014.41 2.919L67.75 9.354a3.356 3.356 0 01-.546.546c-.21.154-.504.231-.882.231-.252 0-.49-.056-.714-.168a9.682 9.682 0 01-.714-.399 18.338 18.338 0 00-.84-.525 5.09 5.09 0 00-1.07-.525 7.833 7.833 0 00-1.45-.42c-.546-.112-1.19-.168-1.932-.168-1.176 0-2.254.217-3.234.651a7.096 7.096 0 00-2.52 1.911c-.7.826-1.246 1.848-1.638 3.066-.378 1.204-.567 2.583-.567 4.137 0 1.568.21 2.961.63 4.179.434 1.218 1.015 2.247 1.743 3.087a7.559 7.559 0 002.583 1.89c.98.434 2.03.651 3.15.651.644 0 1.232-.028 1.764-.084a9.34 9.34 0 001.491-.315c.462-.154.896-.35 1.302-.588a8.828 8.828 0 001.24-.945c.167-.14.35-.252.545-.336.196-.098.4-.147.61-.147zM80.95 39.804h-9.408v-2.499c0-.42.154-.784.462-1.092.322-.308.728-.462 1.218-.462h2.31V4.356h-2.31c-.49 0-.896-.154-1.218-.462a1.517 1.517 0 01-.462-1.113V.303h9.408v39.501z"
                fill="#B8C2FA"
            />
        </svg>
    );
}

