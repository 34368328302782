import {useMemo} from "react";

import {IntegrationsApi} from "./IntegrationsApi";
import {useApiBase} from "../ApiContext";

export interface IntegraionsContextProps {
    readonly IntegrationsApi: IntegrationsApi;
}

export function useIntegrationsContext(): IntegraionsContextProps {
    const data = useApiBase();

    const api = useMemo(() => new IntegrationsApi(data), [data]);

    return {
        IntegrationsApi: api,
    };
}
