export enum Routes {
    // Auth
    Auth = "/auth",
    AuthLogin = "/auth/login",
    AuthSignUp = "/auth/signup",

    // App
    CreateWorkflow= "/app/workflow/create/:id",
    CreateWorkflowV2= "/app/workflow-v2/create/:id",
    AppDashboard= "/app/dashboard",
    AppExplore= "/app/explore",
    AppTransfers= "/app/transfers",
    AppMyApps= "/app/my-apps",
    AppGetHelp= "/app/get-help",
    AppWorkflowHistory= "/app/workflow-history"
}
