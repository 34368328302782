import React from "react"
import {WorkflowActionsDTO} from "../../../api/workflow/WorkflowDTO";
import {FieldArray, Formik} from "formik";
import {WorkflowActionItem} from "./WorkflowActionItem";
import _ from "lodash"

interface Props {
    readonly actions: WorkflowActionsDTO[];
    readonly classesMore: any
}

export function WorkflowActionsV2({actions, classesMore}: Props) {

    return (
        <Formik onSubmit={(values) => console.log(values, "first")} enableReinitialize={true} initialValues={{actions: actions}}>
            {({values, setFieldValue}) => {
                console.log("changed:", _.isEqual(values, actions));
                console.log(values);
                return (
                    <FieldArray
                        name="actions"
                        render={(arrayHelpers) => (
                            <>
                                {values.actions && values.actions.length > 0 ? (
                                    values.actions.map((item, index) => (
                                        <WorkflowActionItem setFieldValue={setFieldValue} data={values.actions[index]} classesMore={classesMore} />
                                    ))
                                ) : (
                                    <div onClick={() => arrayHelpers.push(
                                        {
                                             accountId: null,
                                             appId: null,
                                             eventId: null,
                                             mappingSource: null,
                                             previousActionId: null
                                        }
                                    )}>Add</div>
                                )}
                            </>
                        )}
                    />
                )
            }}
        </Formik>
    )
}


