interface HexToRgbOptionsProps {
  readonly opacity?: number;
  readonly fallback?: string;
}

export function hexToRgba(hex: string, options: HexToRgbOptionsProps): string | undefined {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

  const replacedHex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(replacedHex);

  if (result) {
    return `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
      result[3],
      16,
    )}, ${options?.opacity || 1})`;
  }

  return options?.fallback;
}
