import React, {ReactNode} from 'react';
import {I18nProvider} from "../i18n/I18nContext";
import {useShallowEqualSelector} from "../hooks/useShallowSelector";
import {appLanguageSelector} from "../reducers/appReducer";
import {ApiProvider} from "../api/ApiContext";
import {useAuthContext} from "../api/auth/AuthContext";

interface Props {
    readonly children: ReactNode
}

function ProviderContainer({children}:Props) {
    const language = useShallowEqualSelector(appLanguageSelector);
    const { logout } = useAuthContext();

    return (
        <I18nProvider data={{language}}>
            {/*@ts-ignore*/}
            <ApiProvider data={logout}>
            {children}
            </ApiProvider>
        </I18nProvider>
        );
}

export default ProviderContainer;