import React from "react";
import cx from "classnames";
import { SuccessIcon } from "../icons/SuccessIcon";
import { useI18n } from "../../i18n/I18nContext";

interface Props {
    readonly classes: any;
}

function AuthWelcome({ classes }: Props) {
    const { translate } = useI18n();
    return (
        <div className={cx(classes.signUpLeft, classes.signUpSide)}>
            <h1 className={classes.title}>
                {translate("AUTOMATE")} <br />
                {translate("YOUR")} <br />
                <span>{translate("TASKS")}</span>
                <br />
                {translate("WITH_US")}
            </h1>
            <ul>
                <li className={classes.listItem}>
                    <SuccessIcon done />
                    Easy setup, no coding required
                </li>
                <li className={classes.listItem}>
                    <SuccessIcon done />
                    Free forever for core features
                </li>
                <li className={classes.listItem}>
                    <SuccessIcon done />
                    14-day trial of premium features & apps
                </li>
            </ul>
        </div>
    );
}

export default AuthWelcome;
