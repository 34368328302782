import React, {ReactElement, ReactNode, useContext} from "react";

export interface ApiContextDataProps {
    readonly logout?: () => void;
}

export interface ApiContextProps {
    readonly logout?: () => void;
}

export interface ApiProviderProps {
  readonly children: ReactNode;
  readonly data?: ApiContextDataProps;
}

export const ApiContext = React.createContext<ApiContextProps>({});

export function ApiProvider({
                              data = {} as ApiContextDataProps,
                              ...props
                            }: ApiProviderProps): ReactElement<object> {
  return <ApiContext.Provider {...props} value={data}/>;
}

export function useApiBase(): ApiContextProps {
  return useContext(ApiContext);
}
