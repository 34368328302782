import React, {useState} from 'react';
import {makeStyles} from "@mui/styles";
import {Switch, Theme} from "@mui/material";
import {GetWorkflowListProps} from "../../api/workflow/WorkflowDTO";
import cx from "classnames"
import {useWorkflowContext} from "../../api/workflow/WorkflowContext";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import { Loader } from '../ui/Loader';
import {useI18n} from "../../i18n/I18nContext";
import { motion } from 'framer-motion';
import {showError} from "../../utils/NotificationUtils";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        maxWidth: theme.typography.pxToRem(676),
        width: "100%",
        padding: `${theme.typography.pxToRem(24)} ${theme.typography.pxToRem(38)}`,
        borderRadius: theme.typography.pxToRem(8),
        border: "1px solid #EFF2FE",
        background: "#ffffff",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "&.deleted": {
            display: "none"
        }
    },
    itemInfoRoot: {
        display: "flex",
        gap: "20px"
    },
    itemActionsRoot: {
        display: "flex",

    },
    itemInfoLogoRoot: {
        display: "flex",
        alignItems: "center",
        gap: "12px"
    },
    itemLogo: {
        width: "30px",
        height: "30px",
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "contain"
        }
    },
    itemInfoTitleRoot: {
        display: "flex",
        alignItems: "center",
        "& h2": {
            fontSize: "16px",
            lineHeight: "24px",
            color: "#000"
        }
    },
    statusSwitcher: {
        "& .MuiSwitch-thumb": {
            boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)"
        }
    },
    status: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        "& .activated": {
            position: "absolute"
        }
    },
    statusItem: {
        opacity: 0,
        transition: "0.3s",
        "&.active": {
            opacity: 1,
        }
    }
}));

interface Props {
    readonly data: GetWorkflowListProps
}

export function WorkflowListItem({data}: Props) {
    const {WorkflowApi} = useWorkflowContext();
    const {translate} = useI18n();
    const [deleted, setDeleted] = useState<boolean>(false);

    const label = {inputProps: {'aria-label': 'Switch demo'}};
    const [checked, setChecked] = useState(data.active);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoading(true);
        if (event.target.checked) {
            WorkflowApi.activateWorkflow({data: {id: data.id}}).then(() => {
                setChecked(true);
                setLoading(false);
            }).catch(err => {
                showError(err);
                setLoading(false)
            })
        } else {
            WorkflowApi.deActivateWorkflow({data: {id: data.id}}).then(() => {
                setChecked(false);
                setLoading(false)
            }).catch(err => {
                showError(err);
                setLoading(false)
            })
        }
    };

    const handleDelete = () => {
        WorkflowApi.deleteWorkflow({params: {workflowId: data.id}}).then(() => {
            toast.success("Deleted");
            setDeleted(true)
        }).catch(err => {
            showError(err)
        })
    };

    return (
        <motion.div layout className={cx(classes.root, {
            "deleted": deleted
        })}>
            <Link to={`/app/workflow/create/${data.id}`} className={classes.itemInfoRoot}>
                <div className={classes.itemInfoLogoRoot}>
                    {
                        data.chainLogos?.slice(0, 3).map((item, index) => {
                            return (
                                <div key={index} className={classes.itemLogo}>
                                    <img src={item && item !== "nologo" ? item : "https://reactjs.org/favicon.ico"}
                                         alt=""/>
                                </div>
                            )
                        })
                    }
                </div>
                <div className={classes.itemInfoTitleRoot}>
                    <h2>{data.name}</h2>
                </div>
            </Link>
           <Box sx={{display: "flex" }}>
               {/*<IconButton onClick={handleDelete}>*/}
               {/*    <DeleteIcon />*/}
               {/*</IconButton>*/}
               <div className={classes.itemActionsRoot}>

                   {!loading ? <Switch className={classes.statusSwitcher}
                                       {...label}
                                       checked={checked}
                                       onChange={handleStatusChange}
                   /> : <Loader />}
                   <div className={classes.status}>
                       <p className={cx("disabled", !checked && "active", classes.statusItem)}>{translate("DISABLED")}</p>
                       <p className={cx("activated", checked && "active", classes.statusItem)}>{translate("ACTIVE")}</p>
                   </div>
               </div>
           </Box>
        </motion.div>
    );
}

