import React from 'react';
import {StepsTypeV2} from "../WorkflowEditorV2";
import {WorkflowAppSelectorV2} from "./WorkflowAppSelectorV2";
import {WorkflowAccountSelectV2} from "./WorkflowAccountSelectV2";
import {WorkflowEditorTriggerDTO} from "../../../api/workflow/WorkflowDTO";
import {WorkflowStepsV2} from "./WorkflowStepsV2";

interface Props {
    readonly data: WorkflowEditorTriggerDTO
    readonly setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
    readonly stepInfo: any
    readonly onSetStepInfo: (info: any) => void
}


export function WorkflowActionStepsWrapper({data,setFieldValue,onSetStepInfo,stepInfo}: Props) {
    const steps = [
        {
            label: StepsTypeV2.ChooseAppEndEvent,
            id: 1,
            done: Boolean(data.appId) && Boolean(data.eventId),
            hide: false,
            component: WorkflowAppSelectorV2,
            fieldName: ["action[0].appId","action[0].eventId"]
        },
        {
            label: StepsTypeV2.ChooseAccount,
            id: 2,
            done: Boolean(data.accountId),
            hide: Boolean(data.appId === 10019),
            component: WorkflowAccountSelectV2,
        },
    ];
    return (
        <WorkflowStepsV2
            isTrigger={false}
            onSetStepInfo={onSetStepInfo}
            stepInfo={stepInfo}
            steps={steps}
            data={data}
            setFieldValue={setFieldValue}
        />

    );
}

