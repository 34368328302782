import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {IconButton, Theme} from "@mui/material";
import {StepsType} from "./workflowEditor";
import WorkflowAppSelector from "./WorkflowAppSelector";
import {WorkflowAccountSelector} from "./WorkflowAccountSelector";
import {WorkflowSteps} from "./WorkflowSteps";
import {deepUpdate} from "immupdate";
import WorkflowActionSetup from "./WorkflowActionSetup";
import {useIntegrationsContext} from "../../api/integrations/IntegrationsContext";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {toast} from "react-toastify";
import {useI18n} from "../../i18n/I18nContext";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        maxWidth: theme.typography.pxToRem(443),
        minWidth: theme.typography.pxToRem(300),
        width: "100%",
        background: "#fff",
        boxShadow: "0px 14px 24px #C6C8D7",
        borderRadius: "16px",
    },
    addPlaceholder: {
        border: "2px dashed #3F51B5",
        boxShadow: "none",
        padding: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    }
}));

interface Props {
    readonly classesMore: any,
    readonly action: any
    readonly setLastActionId: (id: number) => void
}

export function WorkflowAction({classesMore, action,setLastActionId}: Props) {
    const classes = useStyles();
    const {translate} = useI18n();
    const {IntegrationsApi} = useIntegrationsContext();
    const [actionInfo, setActionInfo] = useState({
        eventName: "Action Name",
        appName: "Application Name",
        logo: "https://reactjs.org/favicon.ico"
    });

    const [actionData, setActionsData] = useState(action);

    const [steps, setSteps] = useState([
        {
            label: StepsType.ChooseAppEndEvent,
            id: 1,
            done: false,
            component: WorkflowAppSelector,
        },
        {
            label: StepsType.ChooseAccount,
            id: 2,
            done: false,
            component: WorkflowAccountSelector,
        },
        {
            label: StepsType.SetupAction,
            id: 3,
            done: false,
            component: WorkflowActionSetup,
        },
    ]);

    const getStepInfo = (id: typeof action.appId) => {
        IntegrationsApi.getIntegrationById({params: {id: id}}).then(res => {
            setActionInfo({
                appName: res.payload.name,
                eventName: res.payload.description,
                logo: res.payload.logoUrl ?? "https://reactjs.org/favicon.ico"
            })
        })
    };

    const handleActionChange = useCallback((name: string, value: any) => {
        setActionsData((a: any) => ({
            ...a,
            //@ts-ignore
            [name]: value,
        }));
        if (name === "appId") {
            getStepInfo(value);
            let newSteps: typeof steps = [];
            steps.forEach((item) => {
                const newItem = item;
                newItem.done = false;
                newSteps.push(newItem)
            });
            setSteps(newSteps)
        }

        if (name === "workflowActionId") {
            setLastActionId(value)
        }
    }, [steps]);

    const onDone = (type: StepsType) => {
        setSteps(prev => {
            return deepUpdate(prev)
                .at(steps.findIndex((x) => x.label === type))
                .abortIfUndef()
                .at("done")
                .modify(() => true)
        });
    };

    useEffect(() => {
        setActionsData(action);
        if (action.appId) {
            getStepInfo(action.appId)
        }
        if (action?.appId && action?.eventId) {
            onDone(StepsType.ChooseAppEndEvent);
        }
        if (action?.accountId) {
            onDone(StepsType.ChooseAccount);
        }
        if (action?.mappingSource) {
            onDone(StepsType.SetupAction);
        }
    }, []);


    return (
        <>
            <div className={classes.root}>
                <div className={classesMore.triggerHeader}>
                    <div className={classesMore.triggerHeaderTitleRoot}>
                        <div className={classesMore.triggerLogo}>
                            <img src={actionInfo.logo} alt="logo"/>
                        </div>
                        <div className={classesMore.triggerInfo}>
                            <p className={classesMore.triggerName}>{actionInfo.eventName}</p>
                            <h2 className={classesMore.triggerAppName}>
                                {actionInfo.appName}
                                {" "}
                                {action.workflowActionId ?? ""}
                            </h2>
                        </div>
                    </div>
                      <IconButton title={translate("COPY_ACTION_ID")} onClick={() => {
                        navigator.clipboard.writeText(actionData?.eventId).then(() => {
                           toast.success(translate("COPIED_TO_CLIPBOARD"),  {draggable: false})
                        })
                    }}>
                        <ContentCopyIcon/>
                    </IconButton>
                </div>
                <WorkflowSteps
                    data={actionData}
                    steps={steps}
                    stepInfo={actionInfo}
                    onDone={(type: StepsType) => onDone(type)}
                    handleChange={(name: string, value: any) => {
                        handleActionChange(name, value)
                    }
                    }
                />
            </div>
        </>
    );
}



