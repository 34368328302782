import React, {ReactNode, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import AppHeader from "./AppHeader";
import AppSidebar from "./AppSidebar";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        background: theme.palette.background.default
    },
    main: {
        flex: "1 1",
        overflow: "hidden",
        display: "flex"
    },
    content: {
        flex: "1 1",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column"
    }
}));

interface Props {
    readonly children: ReactNode;

}

function AppWrapper({children}: Props) {
    const classes = useStyles();
    const [close ,setClose] = useState<boolean>();
    return (
        <div className={classes.root}>
             <AppHeader onMenuClick={() => setClose(prev => !prev)}/>
            <div className={classes.main}>
                {!window.location.pathname.includes("/auth") && <AppSidebar closed={close}/>}
                <div className={classes.content}>{children}</div>
            </div>
        </div>
    );
}

export default AppWrapper;