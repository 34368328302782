import React, {useState} from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useI18n} from "../../../i18n/I18nContext";
import {SuccessIcon} from "../../icons/SuccessIcon";


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        "& .MuiStepLabel-root": {
            flexDirection: "row-reverse",
            cursor: "pointer !important",
            padding: "16px 23px",
            "& .MuiStepLabel-iconContainer": {
                display: "none"
            },

            "& .MuiStepLabel-label": {
                fontSize: 16,
                fontWeight: "bold",
                color: "#000 !important",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%"
            }
        },

        "& .MuiStepConnector-root": {
            margin: "none",
            borderBottom: "1px solid #f1f1f1"
        },

        "& .MuiStepContent-root": {
            border: "none",
            margin: 0,
            padding: "0 23px !important"
        },

        "& .MuiStepConnector-line": {
            display: "none"
        },

        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #E8E8E8",
            borderRadius: 5
        }
    }
}));

interface Props {
    readonly steps: any[];
    readonly data: any
    readonly isTrigger: boolean
    readonly setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
    readonly onSetStepInfo: (info: any) => void
    readonly stepInfo: any
}

export function WorkflowStepsV2({steps, setFieldValue, data, isTrigger, onSetStepInfo, stepInfo}: Props) {
    const classes = useStyles();
    const {translate} = useI18n();
    const [activeStep, setActiveStep] = useState(0);
    return (
        <Stepper activeStep={activeStep} orientation="vertical" className={classes.root}>
            {steps.map((item, index) => (
                !item.hide && (
                    <Step>
                        <StepLabel onClick={() => {
                            (item.done || steps[index - 1]?.done || index === 0) && setActiveStep(index);
                            activeStep === index && setActiveStep(-1)
                        }}>
                            <span>{translate(item.label)}</span>
                            <SuccessIcon done={item.done}/>
                        </StepLabel>
                        <StepContent>
                            <div>
                                <item.component
                                    onSetFieldValue={setFieldValue}
                                    data={data}
                                    fieldName={item.fieldName}
                                    isTrigger={isTrigger}
                                    onSetStepInfo={onSetStepInfo}
                                    stepInfo={stepInfo}
                                />
                            </div>

                            {!(isTrigger && index === steps.length - 1) && <Box sx={{my: 2, textAlign: "right"}}>
                                <Button
                                    variant="contained"
                                    disabled={!item.done}
                                    type={(index === steps.length - 1 || Boolean(data?.appId === 10019)) ? 'submit' : "button"}
                                    onClick={() => setActiveStep(index + 1)}
                                >
                                    {(index === steps.length - 1 || Boolean(data?.appId === 10019)) ? 'Finish' : 'Continue'}
                                </Button>

                            </Box>}
                        </StepContent>
                    </Step>
                )
            ))}
        </Stepper>
    );
}

