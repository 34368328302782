import React, {useEffect, useState} from 'react';
import {StepsType} from "./workflowEditor";
import {useConnectionsContext} from "../../api/connections/ConnectionsContext";
import {useI18n} from "../../i18n/I18nContext";
import {AppAutoComplete} from "../ui/AppAutoComplete";
import {SelectOptions} from "../../constants/AppConstants";

interface Props {
    readonly handleChange: (name: any, value: any) => void;
    readonly stepType: StepsType,
    readonly onDone: (type: StepsType) => void,
    readonly data: any
}

export function WorkflowAccountSelector({handleChange, onDone, stepType, data}: Props) {
    const {translate} = useI18n();
    const [list, setList] = useState<SelectOptions[]>([]);
    const {ConnectionsApi} = useConnectionsContext();

    useEffect(() => {
        ConnectionsApi.getConnectionsList(data.appId).then(res => {
            let data: any = [];
            res.payload?.list?.map((x: any) => data.push({label: x.name, value: x.id}));
            setList(data);
        });
    }, [data, onDone, ConnectionsApi]);


    return (
        <div>
            <AppAutoComplete sx={{mb: 2}}
                       label={translate("CHOOSE_ACCOUNT")}
                       options={list}
                       value={data.accountId}
                       onValueChange={(value) => {
                           handleChange("accountId", Number(value));
                           onDone(stepType)
                       }}/>
        </div>
    );
}

