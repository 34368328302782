import React from 'react';
import {useFormik} from "formik";
import {TextField} from "@mui/material";
import {AppButton} from "../ui/AppButton";
import {useI18n} from "../../i18n/I18nContext";
import {useWorkflowContext} from "../../api/workflow/WorkflowContext";
import {toast} from "react-toastify";

interface Props {
    readonly data: {name: string, description: string, id: number},
    readonly onChanged: (name: string) => void
}

function WorkflowUpdateForm({data,onChanged}: Props) {

    const {WorkflowApi} = useWorkflowContext();
    const formik = useFormik({
        initialValues: {
            name: data.name,
            description: data.description,
        },
        onSubmit: (values) => WorkflowApi.updateWorkflow({...values, id: data.id}).then((res) => {
            onChanged(res.payload.name)
        }).catch(error => {
            toast.error(error.errors.message)
        }),
    });
    const {translate} = useI18n();
    return (
        <form onSubmit={formik.handleSubmit}>
            <TextField fullWidth variant="outlined"
                       value={formik.values.name}
                       onChange={formik.handleChange}
                       error={formik.touched.name && Boolean(formik.errors.name)}
                       helperText={formik.touched.name && formik.errors.name}
                       type="text"
                       placeholder={translate("NAME")}
                       name="name"
                       id="name"
                       sx={{my: 1}}
            />
            <TextField fullWidth variant="outlined"
                       value={formik.values.description}
                       onChange={formik.handleChange}
                       error={formik.touched.description && Boolean(formik.errors.description)}
                       helperText={formik.touched.description && formik.errors.description}
                       type="text"
                       placeholder={translate("DESCRIPTION")}
                       name="description"
                       id="description"
                       sx={{my: 1}}
            />
            <AppButton type="submit" variant="contained">{translate("UPDATE")}</AppButton>
        </form>
    );
}

export default WorkflowUpdateForm;