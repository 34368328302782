export const objToFieldsArray = (obj: any) => {
    let result: any[] = [];
    let count = 0;

    function f(object: any, parentObj?: any) {
        let keys = Object.keys(object);
        for (let i = 0; i < keys.length; i++) {
            if (object[keys[i]] !== null) {
                if (typeof object[keys[i]] !== "object") {
                    count++;
                    result.push({
                        label: keys[i],
                        value: object[keys[i]].toString(),
                        parentObj: parentObj,
                        id: count
                    });
                } else {
                    count++;
                    f(object[keys[i]], parentObj !== undefined ? `${parentObj}.${keys[i]}` : keys[i])
                }
            }

        }
    }

    f(obj);
    return result
};

export const extractBodyFromStringSource = (stringCode: string, withValue: boolean = false) => {
    const source = stringCode
        ?.replace(/(\\n|\\r|\r|\n)/gm, "")
        .replace(/\s+/g, " ");
    const sourceBody = source.substr(0, source.search("return"));
    let object = eval(`
                function returnObject() {
                    ${sourceBody
        .replaceAll(/bundle.[a-zA-Z].[^,}]*/gm, '"' + "$&" + '"')
        .replaceAll("bundle.inputData.", "response.")
    }

                    let newObject = {}
                    options.body = Object.keys(options.body).map(item => {
                            newObject[item] = !withValue ? "" : options.body[item]
                    })
                     return newObject
                  }
                  
                returnObject()
        `);

    return object
};


export function getArrayItemByFieldValue(arr: [], value: any, field: string): any {
    return arr.filter(item => item[field] === value)[0]
}