import { I18nSchema } from "./I18nSchema";
import { I18nEnglish } from "./I18nEnglish";

export const I18nRussian: I18nSchema = {
    ...I18nEnglish,

    "WELCOME": "Добро пожаловать!",
    "LOGIN_FORM_TITLE": "Присоединяйтесь к сообществу",
    "EMAIL": "Э-почта",
    "PASSWORD": "Пароль",
    "SIGN_IN": "Вход",
    "SIGN_UP": "Зарегистрироваться\n",
    "SIGN_IN_NO_ACCOUNT": "Нет аккаунта ?",
    "SIGN_IN_NO_ACCOUNT_CREATE_ONE": "Создай!",
    "FIRST_NAME": "Имя",
    "LAST_NAME": "Фамилия",
    "ALREADY_HAVE_ACCOUNT": "уже есть аккаунт?",
    "REQUIRED": "Обязательное поле",
    "EMAIL_ERROR": "Э-почта должна быть действительной",
    "WRONG_CREDENTIALS": "Имя пользователя или пароль неверны",
    "SUCCESSFUL_LOGIN": "Успешный вход",
    "SUCCESSFUL_SIGN_UP": "Аккаунт успешно создан!",
    "BUILD_INTEGRATION": "Создать интеграцию",
    "START_INTEGRATION": "Начать интеграцию",
    "LOGOUT": "Выйти",
    "NAME": "Имя",
    "HOME_PAGE": "Главная страница",
    "VERSION": "Версия",
    "STATUS": "Статус",
    "ACTIONS": "Действие",
    "INTEGRATIONS": "Интеграции",
    "EMPTY_LIST": "Пустой список",
    "MAIN_MENU": "Главная меню",
    "DESCRIPTION": "Описание",
    "CANCEL": "Отменить",
    "CREATE": "Создат",
    "DELETED": "Удалено",
    "WITH_GOOGLE": "через Google",
    "WITH_FACEBOOK": "через Facebook",
    "SIGNUP_WITH_EMAIL": "или зарегистрируйтесь по э-почте",
    "SIGNIN_WITH_EMAIL": "или войдите по э-почте",
    "PRIVACY_POLICY_ALERT": "Регистрируясь, вы соглашаетесь с Условиями использования и Политикой конфиденциальности Tact.",
    "PASSWORD_LENGTH": "пароль должен состоять не менее чем из 6 символов\n",
    "AUTOMATE": "АВТОМАТИЗИРУЙТЕ",
    "YOUR": "СВОИ",
    "TASKS": "задачи",
    "WITH_US": "С НАМИ",
    "DASHBOARD": "Приборная панель",
    "APPLICATIONS": "Приложения",
    "CHOOSE_APP_AND_EVENT": "Выберите приложение и событие",
    "CHOOSE_ACCOUNT": "Выберите аккаунт",
    "SETUP_TRIGGER": "Настройте триггер",
    "SETUP_ACTION": "Настройте действие",
    "TEST_TRIGGER": "Проверьте триггер",
    "TEST_ACTION": "Проверьте действие",
    "NAME_YOUR_WORKFLOW": "Назовите свой рабочий процесс",
    "CREATE_WORKFLOW": "Создать рабочий процесс",
    "CONTINUE": "Далее",
    "EMPTY": "Пусто",
    "WORKFLOW_TEST_TRIGGER_TITLE": "Мы проверим, правильно ли подключена учетная запись и правильно ли настроен ваш триггер.",
    "UPDATE": "Обновить",
    "DISABLED": "Отключён",
    "ACTIVE": "Активный",
    "CHANGE_APP": "Изменить",
    "CURRENT_APP": "Текущее приложение",
    "CHOOSE_TRIGGER_EVENT": "Выбрать событие триггера",
    "CHOOSE_ACTION_EVENT": "Выбрать событие действие",
    "COPY_ACTION_ID": "Копировать ID действия",
    "COPIED_TO_CLIPBOARD": "Скопировано в буфер обмена",
    "PLEASE_SAVE_CHANGES": "Пожалуйста, сохраните изменения",
    "SEARCH_HERE": "Поиск...",
    "WORKFLOW_WITH_NAME": "Workflow с именем:",
    "WAS_NOT_FOUND": "не был найден"
};
