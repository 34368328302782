import { Redirect, Route, Switch } from "react-router";
import React, { Suspense, useMemo } from "react";

import { useShallowEqualSelector } from "../hooks/useShallowSelector";
import {viewModeSelector} from "../reducers/appReducer";
import {ViewMode} from "../api/dto/AppDTO";
import {Routes} from "../constants/Routes";
import AuthContainer from "./auth/AuthContainer";
import Cookies from "js-cookie"
import {AppRootContainer} from "./AppRootContainer";


export function RootContainer() {

  const token = Cookies.get("token");
  const viewMode = useShallowEqualSelector(viewModeSelector);


  const isAuthorized = useMemo(() => Boolean(token && viewMode !== ViewMode.Undetermined ), [
    token,
    viewMode,
  ]);

  return (
    <Suspense
      fallback={
        <div className="pre-loader">
          <h1>Loading...</h1>
        </div>
      }
    >
      <Switch>
        {isAuthorized && <Redirect to={Routes.AppDashboard} from="/auth" />}
        {isAuthorized && <Redirect exact={true} to={Routes.AppDashboard} from="/" />}
        {isAuthorized && <Route component={AppRootContainer} />}

        {!isAuthorized && <Route component={AuthContainer} path={Routes.Auth} />}
        {!isAuthorized && <Redirect to={Routes.AuthLogin} />}


      </Switch>
    </Suspense>
  );
}
