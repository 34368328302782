import {useMemo} from "react";

import {ConnectionsApi} from "./ConnectionsApi";
import {useApiBase} from "../ApiContext";

export interface ConnectionsContextProps {
    readonly ConnectionsApi: ConnectionsApi;
}

export function useConnectionsContext(): ConnectionsContextProps {
    const data = useApiBase();

    const api = useMemo(() => new ConnectionsApi(data), [data]);

    return {
        ConnectionsApi: api,
    };
}
