import React from "react";
import cx from "classnames";
import {CircularProgress, useTheme} from "@mui/material";
import {makeStyles} from "@mui/styles";

interface StylesProps {
    readonly size: number;
    readonly color: string;
}

const useStyle = makeStyles({
    root: {
        display: "flex",
        justifyContent: "center",
        width: "100%"
    },
    progress: {
        color: (props: StylesProps) => `${props.color}!important` as any,
    },
});

interface Props {
    readonly size?: number;
    readonly color?: string;
    readonly className?: string;
    readonly rootClassName?: string;
}

export function Loader({ size = 24, color, className, rootClassName }: Props) {
    const theme = useTheme();

    // @ts-ignore
    const classes = useStyle({ size, color: color || theme.palette.primary.main });

    return (
      <div className={cx(classes.root, rootClassName)}>
          <CircularProgress
              size={size}
              className={cx(classes.progress, className)}
          />
      </div>
    );
}
