import {useMemo} from "react";

import {ActionApi} from "./ActionApi";
import {useApiBase} from "../ApiContext";

export interface ActionContextProps {
    readonly ActionApi: ActionApi;
}

export function useActionContext(): ActionContextProps {
    const data = useApiBase();

    const api = useMemo(() => new ActionApi(data), [data]);

    return {
        ActionApi: api,
    };
}
