import qs from "qs";
import {pathParams} from "path-params";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import Cookies from "js-cookie"
import {AppError, AppErrorProps} from "../helpers/AppError";
export interface ApiProps {
    readonly token?: string;
    readonly logout?: () => void;
}

export interface Options extends AxiosRequestConfig {
    readonly query?: object;
    readonly params?: object;
}


export class BaseApi {
    private readonly token?: string;
    private readonly logout?: () => void;

    constructor({token, logout}: ApiProps) {
        this.token = token;
        this.logout = logout;
    }

    private queryToString(query: object = {}): string {
        return qs.stringify(query);
    }

    private createRequestUrl(url: string, query: object = {}, params: object = {}): string {
        const formattedUrl = pathParams(url, params);

        return [formattedUrl, this.queryToString(query)].filter(Boolean).join("?");
    }

    private createRequestOptions(options: AxiosRequestConfig): AxiosRequestConfig {
        const {headers = {}} = options;
        const token = Cookies.get("token");
            headers.Authorization = `Bearer ${token}` ;

        return {
            baseURL: "https://connector.kpi.com/api/v1/",
            ...options,
            headers,
            //@ts-ignore
        };
    }


    private request<T>(url: string, options: Options = {}): Promise<T> {
        const {query, params, ...kyOptions} = options;

        const formattedOptions = this.createRequestOptions(kyOptions);
        const formattedUrl = this.createRequestUrl(url, query, params);

        return axios.request({
            url: formattedUrl,
            ...formattedOptions,
        });
    }

    private jsonRequest<TData>(url: string, options?: Options): Promise<TData> {
        return new Promise<TData>((resolve, reject) => {
            this.request<AxiosResponse>(url, options)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data) {
                            return response.data
                        } else if (response) {
                            return response
                        } else {
                            return this.parseError(response);
                        }
                    }
                }).then(res => resolve(res as any))
                .catch((err) => {
                    const error = err.response.data;
                    if (error) {
                        reject(
                            this.parseError({
                                statusText: error.status,
                                errors: [{ userMsg: error.errors.message ? error.errors.message : error.errors  }],
                            } as any),
                        );
                    } else {
                        reject(
                            this.parseError({
                                statusText: "Unknown",
                                errors: [{ userMsg: "Unknown" }],
                            } as any),
                        );
                    }
                });
        });
    }

    private parseError(response: Response): AppError {
        const error = new Error(response.statusText) as AppErrorProps;

        error.status = response?.status;
        // @ts-ignore
        error.data = response?.errors;

        return new AppError(error);

    }

    public get<T = any>(url: string, options?: Options): Promise<T> {
        return this.jsonRequest(url, {...options, method: "get"});
    }

    public post<T = any>(url: string, options?: Options): Promise<T> {
        return this.jsonRequest(url, {...options, method: "post"});
    }

    public patch<T = any>(url: string, options?: Options): Promise<T> {
        return this.jsonRequest(url, {...options, method: "patch"});
    }

    public put<T = any>(url: string, options?: Options): Promise<T> {
        return this.jsonRequest(url, {...options, method: "put"});
    }

    public delete<T = any>(url: string, options?: Options): Promise<T> {
        return this.jsonRequest(url, {...options, method: "delete"});
    }
}