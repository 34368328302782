import {Button, ButtonProps, Theme, useTheme} from '@mui/material';
import React, {ReactNode} from 'react';
import {makeStyles} from "@mui/styles";
import {hexToRgba} from "../../utils/FormatUtils";
import cx from "classnames"
import {Loader} from "./Loader";
interface StylesProps {
    readonly background: string;
}
const useStyle = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: (props: StylesProps) => `${props.background}!important` as any,
        color: theme.palette.common.white,
        fontSize: theme.typography.pxToRem(15),
        //fontFamily: "Lato !important",
        padding: `${theme.typography.pxToRem(12)} !important` as any,
        "&.css-8c72aq-MuiButtonBase-root-MuiButton-root:hover" : {
            background: hexToRgba(theme.palette.primary.main, {opacity: 0.9})
        },
        "& .MuiSvgIcon-root": {
            marginRight: theme.typography.pxToRem(9),
            fontSize: theme.typography.pxToRem(22),
        },
        "&:disabled": {
            background: `${theme.palette.grey[700]}!important` as any,
            color: "#f4f4f4!important",
            cursor: "not-allowed !important" as any,
            pointerEvents: "all !important" as any
        }
    }
}));

interface Props extends ButtonProps{
    readonly className?: string;
    readonly background?: string;
    readonly icon?: ReactNode
    readonly children: ReactNode;
    readonly loading?: boolean;
    readonly [key: string]: any
}

export function AppButton({children, className,loading, background, icon, ...props}:Props) {
    const theme = useTheme();

    const classes = useStyle({background: background || theme.palette.text.primary});
    return (
        <Button {...props} variant="contained"  className={cx(classes.root, className)}>
            {loading && <Loader color="#fff" />}
            {!loading && children}
        </Button>
    );
}

