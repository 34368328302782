import React, {useEffect, useState} from 'react';
import {Loader} from "../ui/Loader";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {useWorkflowContext} from "../../api/workflow/WorkflowContext";
import {useParams} from "react-router";
import cx from "classnames"
import {AppButton} from "../ui/AppButton";
import {WorkflowAction} from "./WorkflowAction";


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        maxWidth: theme.typography.pxToRem(443),
        minWidth: theme.typography.pxToRem(300),
        width: "100%",
        background: "#fff",
        boxShadow: "0px 14px 24px #C6C8D7",
        borderRadius: "16px",
    },
    addPlaceholder: {
        border: "2px dashed #3F51B5",
        boxShadow: "none",
        padding: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    }
}));

interface Props {
    readonly classesMore: any
}

export function WorkflowActions({classesMore}: Props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const {WorkflowApi} = useWorkflowContext();
    const [lastActionId, setLastActionId] = useState<number | null>(null);
    const params: any = useParams();

    const [actions, setActions] = useState<any>([
        {
            accountId: null,
            appId: null,
            eventId: null,
            mappingSource: "",
            previousActionId: null,
            workflowActionId: null,
            id: 1,
            isNew: true
        }
    ]);

    useEffect(() => {
        WorkflowApi.getWorkflowDetails(params.id)
            .then((res) => {
                const data = res.payload;
                if(data.actions.length > 0) {
                    const actions = data.actions;
                    let itemId = 1;
                    const newArr: any[] = actions.map((item: any) => {
                        itemId++;
                        return ({
                            accountId: item?.account?.id,
                            appId: item?.app?.id,
                            eventId: item?.event?.id,
                            mappingSource: item?.mappingSource,
                            previousActionId: item?.previousAction?.id ?? null,
                            workflowActionId: item?.id,
                            id: itemId,
                            isNew: false
                        });
                    });
                    setActions(newArr.sort((a, b) => Number(a.previousActionId) - Number(b.previousActionId)));
                    setLoading(true);
                }
            })
            .catch(() => {
                setLoading(true);
            });
    }, [WorkflowApi, params.id]);


    const addNewAction = () => {
        setActions((prev: any) => [...prev, {
            accountId: null,
            appId: null,
            eventId: null,
            mappingSource: null,
            previousActionId: prev[prev.length - 1].workflowActionId ?? lastActionId,
            workflowActionId: null,
            id: prev[prev.length - 1].id + 1,
            isNew: true
        }])
        setLastActionId(null)
    };


    return (
        <>
            {!loading ? <Loader size={50}/> :
                <>
                    {
                        //@ts-ignore
                        actions.map(action => {
                            return (
                                <WorkflowAction setLastActionId={(id: number) => setLastActionId(id)} key={action.id}  action={action} classesMore={classesMore} />
                            )
                        })
                    }
                    <div className={cx(classes.root, classes.addPlaceholder)}>
                        <AppButton onClick={addNewAction} disabled={!Boolean(actions[actions.length - 1].workflowActionId ?? lastActionId)}>Add New Action</AppButton>
                    </div>
                </>
            }

        </>
    );
}



