import React from 'react';
import Skeleton from "react-loading-skeleton";
import cx from "classnames"

interface Props {
    readonly count?: number[];
    readonly width?: number;
    readonly height?: number;
    readonly className?: string;
}

function SkeletonLoad({count=[0], height, width = 200, className, ...props}: Props) {
    return (
        <div>
            {
                count.map((x, index) => {
                    return (
                        <Skeleton key={index} width={width} {...props} className={cx("skeleton_load", className)} height={height} />
                    )
                })
            }
        </div>
    );
}

export default SkeletonLoad;