import {BaseApi, Options} from "../BaseApi"


export class AuthApi extends BaseApi{
    public signIn(options?: Options): Promise<any> {
        return this.post("/login", options)
    }

    public signUp(options?: Options): Promise<any> {
        return this.post("/signup", options)
    }

    public getUserData() {
        return this.get("/user/fetch")
    }
}