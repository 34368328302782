import React, {useEffect, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {IconButton, Theme} from "@mui/material";
import {Logo} from '../icons/Logo';
import {useAuthContext} from "../../api/auth/AuthContext";
import AppLanguageSwitcher from "../../api/auth/AppLanguageSwitcher";
import SkeletonLoad from "../ui/SkeletonLoad";
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import cx from "classnames"
import Cookies from "js-cookie";
import {changeViewMode} from "../../reducers/appReducer";
import {ViewMode} from "../../api/dto/AppDTO";
import {useHistory} from "react-router";
import {useDispatch} from "react-redux";
import {showError} from "../../utils/NotificationUtils";

const useStyle = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
        height: theme.typography.pxToRem(80),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: theme.palette.common.white,
        padding: `0 ${theme.typography.pxToRem(32)} 0 ${theme.typography.pxToRem(26)}`,
        borderBottom: `1px solid #EBEEFB`,
    },
    account: {
        display: "flex",
        alignItems: "center",
        //fontFamily: "Roboto",
        marginLeft: theme.typography.pxToRem(32)
    },
    accountInfo: {
        textAlign: "right",
        //fontFamily: "Roboto"
    },
    name: {
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(21),
    },
    email: {
        color: "rgba(0, 0, 0, 0.4)",
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(18)
    },
    img: {
        width: theme.typography.pxToRem(40),
        height: theme.typography.pxToRem(40),
        background: theme.palette.grey[700],
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.common.white,
        marginLeft: theme.typography.pxToRem(10),
        perspective: "600px",
        overflow: "hidden",
        position: "relative",
        "&:hover .logout": {
            opacity: 1,
            pointerEvents: "all"
        }
    },
    acions: {
        display: "flex"
    },
    left: {
        display: "flex",
        alignItems: "center"
    },
    logo: {
        marginLeft: theme.typography.pxToRem(73)
    },
    logout: {
        transition: "0.4s",
        opacity: 0,
        pointerEvents: "none",
        position: "absolute",
        width: "100%",
        height: "100%",
        background: "rgba(0,0,0,0.7)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
    },
    burgerBtn: {
        "@media (max-width: 1200px)": {
            display: "none !important" as any
        }
    }
}));

interface Props {
    readonly onMenuClick: () => void
}

function AppHeader({onMenuClick}:Props) {
    const classes = useStyle();
    const {AuthApi} = useAuthContext();
    const [user, setUser] = useState<any>(null);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        let isMount = true;
        if (isMount) {
            if (!window.location.pathname.includes("/auth")) {
                AuthApi.getUserData().then(res => {
                    if (res.status === "OK") {
                        setUser(res.payload)
                    }
                }).catch(err => {
                    if   (err?.message === "UNAUTHORIZED") {
                        Cookies.remove("token");
                        dispatch(changeViewMode({ viewMode: ViewMode.Undetermined }));
                        history.replace({pathname: "/"})
                    } else {
                        showError(err)
                    }
                })
            }
        }
        return () => {
            isMount = false
        }
    }, [AuthApi]);
    return (
        <div className={classes.root}>
            <div className={classes.left}>
                <IconButton onClick={() => onMenuClick()} className={classes.burgerBtn}>
                    <MenuIcon color="primary"/>
                </IconButton>
                <Logo className={classes.logo}/>
            </div>
            <div className={classes.acions}>
                <AppLanguageSwitcher/>
                {!window.location.pathname.includes("/auth") && <div className={classes.account}>
                    <div className={classes.accountInfo}>
                        <h3 className={classes.name}>{user?.fullName || <SkeletonLoad width={170}/>}</h3>
                        <span className={classes.email}>{user?.email || <SkeletonLoad/>}</span>
                    </div>
                    <div className={classes.img}>
                        <h2>{user?.firstName.slice(0, 1).toLocaleUpperCase()}</h2>
                        <div className={cx(classes.logout, "logout")} title="logout" onClick={() => {
                            Cookies.remove("token");
                            dispatch(changeViewMode({ viewMode: ViewMode.Undetermined }));
                            history.replace({pathname: "/"})
                        }}>
                            <LogoutIcon />
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    );
}

export default AppHeader;