import React, {useState} from 'react';
import {makeStyles} from "@mui/styles";
import {Theme, useMediaQuery} from "@mui/material";
import {Menu} from "../../constants/AppConstants"
import cx from "classnames"
import {Link} from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';
import {useI18n} from "../../i18n/I18nContext";
import Modal from "../ui/Modal";
import WorkflowCreateForm from "../workflow/WorkflowCreateForm";
interface StyleProps {
    readonly closed: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: ({closed}: StyleProps) =>  theme.typography.pxToRem(closed ? 100 : 300),
        borderRight: `1px solid #EBEEFB`,
        background: theme.palette.common.white,
        padding: `${theme.typography.pxToRem(32)} ${theme.typography.pxToRem(26)}`,
        transition: "0.3s",
        overflow: "hidden",
        "@media (max-width: 1200px)": {
            width: `${theme.typography.pxToRem(100)}!important` as any
        }
    },
    item: {
        width: "100%",
        height: theme.typography.pxToRem(48),
        borderRadius: theme.typography.pxToRem(16),
        padding: theme.typography.pxToRem(12),
        marginBottom: theme.typography.pxToRem(25),
        display: "flex",
        alignItems: "center",
        //fontFamily: "Lato"
    },
    active: {
        background: "#F4F6FF",
    },
    title: {
        opacity: ({closed}: StyleProps) => closed ? 0 : 1,
        color: theme.palette.primary.main,
        transition: "0.3s",
        "@media (max-width: 1200px)": {
            opacity: `0 !important` as any
        }
    },
    icon: {
        marginRight: theme.typography.pxToRem(15),
    },
    add: {
        background: "#000000",
        color: theme.palette.common.white,
        justifyContent: "center",
        fontSize: theme.typography.pxToRem(17),
        //fontFamily: "Roboto !important" as any,
        fontWeight: 600,
        "& p": {
            opacity: ({closed}: StyleProps) => closed ? 0 : 1,
            transition: "0.5s 0.3s",
        },
        cursor: "pointer"
    },
    addIcon: {
        transform: "rotate(-45deg)",
    }
}));

interface Props {
    readonly closed: any
}

function AppSidebar({closed}:Props) {
    const classes = useStyles({closed});
    const [createWorkflowModal, setCreateWorkflowModal] = useState(false);
    const {translate} = useI18n();
    const max1200px = useMediaQuery('(max-width:1200px)');
    return (
        <div className={classes.root}>
            <ul>
                <li className={cx(classes.item, classes.add)} onClick={() => {
                    setCreateWorkflowModal(true)
                }}>{(closed || max1200px) ? <ClearIcon className={classes.addIcon} /> : <pre>{translate("CREATE_WORKFLOW")}</pre>}</li>
                {Menu.map((item, index) => {
                    return (
                        <Link key={index} to={item.link}>
                            <li
                                className={cx(classes.item, window.location.pathname.includes(item.link) && classes.active)}>
                                <item.icon color="primary" className={classes.icon}/> <span className={classes.title}>{translate(item.title)}</span>
                            </li>
                        </Link>
                    )
                })}
            </ul>
            <Modal show={createWorkflowModal} onClose={() => setCreateWorkflowModal(false)}>
                <WorkflowCreateForm onCreated={() => setCreateWorkflowModal(false)} />
            </Modal>
        </div>
    );
}

export default AppSidebar;