import {useMemo} from "react";

import {TriggerApi} from "./TriggerApi";
import {useApiBase} from "../ApiContext";

export interface TriggerContextProps {
    readonly TriggerApi: TriggerApi;
}

export function useTriggerContext(): TriggerContextProps {
    const data = useApiBase();

    const api = useMemo(() => new TriggerApi(data), [data]);

    return {
        TriggerApi: api,
    };
}
