import React from 'react';
import {useFormik} from "formik";
import {TextField} from "@mui/material";
import {AppButton} from "../ui/AppButton";
import {useI18n} from "../../i18n/I18nContext";
import {useWorkflowContext} from "../../api/workflow/WorkflowContext";
import {toast} from "react-toastify";
import {useHistory} from "react-router";
import {showError} from "../../utils/NotificationUtils";


interface Props {
    readonly onCreated: () => void
}

export  default  function WorkflowCreateForm({onCreated}: Props) {

    const history = useHistory();
    const {WorkflowApi} = useWorkflowContext();
    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
        },
        onSubmit: (values) => WorkflowApi.createWorkflow({data: values}).then((res) => {
            toast.success("Created");
            onCreated();
            history.replace({pathname: "/app/workflow/create/" + res.payload.id})
        }).catch(error => {
            showError(error)
        }),
    });
    const {translate} = useI18n();
    return (
        <form onSubmit={formik.handleSubmit}>
            <TextField fullWidth variant="outlined"
                       value={formik.values.name}
                       onChange={formik.handleChange}
                       error={formik.touched.name && Boolean(formik.errors.name)}
                       helperText={formik.touched.name && formik.errors.name}
                       type="text"
                       placeholder={translate("NAME")}
                       name="name"
                       id="name"
                       sx={{my: 1}}
            />
            <TextField fullWidth variant="outlined"
                       value={formik.values.description}
                       onChange={formik.handleChange}
                       error={formik.touched.description && Boolean(formik.errors.description)}
                       helperText={formik.touched.description && formik.errors.description}
                       type="text"
                       placeholder={translate("DESCRIPTION")}
                       name="description"
                       id="description"
                       sx={{my: 1}}
            />
            <AppButton type="submit" variant="contained">{translate("CREATE_WORKFLOW")}</AppButton>
        </form>
    );
}

