import React from "react";

export enum MenuState {
  Expanded = "expanded",
  Collapsed = "collapsed",
}

export enum ViewMode {
  Full = "full",
  ReadOnly = "readonly",
  Undetermined = "undetermined",
}

export type SvgProps = React.SVGProps<SVGSVGElement>;

