import { update } from "immupdate";
import { PersistConfig } from "redux-persist";
import {AppLanguage, getDefaultLanguage} from "../i18n/I18nContext";
import {createReducer, createRootReducer, PerformAction} from "../utils/ReducerUtils";
import {AppStoreState} from "../store/RootReducer";
import {ViewMode} from "../api/dto/AppDTO";


export const appReducerPersistConfig: Partial<PersistConfig<AppReducerState>> = {
    whitelist: ["language","viewMode"],
};

interface SwitchLanguageMeta {
    readonly language: AppLanguage;
}


interface ChangeViewModeMeta {
    readonly viewMode: ViewMode;
}


enum ReducerActions {
    SwitchLanguage = "App/SwitchLanguage",
    ChangeViewMode = "App/ChangeViewMode"
}

export interface AppReducerState {
    readonly language: AppLanguage;
    readonly viewMode: ViewMode;
}

function getState(): AppReducerState {
    return {
        language: getDefaultLanguage(),
        viewMode: ViewMode.Undetermined,
    };
}

export const appReducer = createRootReducer<AppReducerState>(
    getState(),

    createReducer([ReducerActions.SwitchLanguage], (state, { meta }) =>
        update(state, { language: meta.language }),
    ),

    createReducer([ReducerActions.ChangeViewMode], (state, { meta }) =>
        update(state, { viewMode: meta.viewMode }),
    ),


);

// ==================
// Selectors
// ==================

export const appLanguageSelector = ({ app }: AppStoreState): AppLanguage => app.language;

export const viewModeSelector = ({ app }: AppStoreState): ViewMode => app.viewMode;


export function changeViewMode(meta: ChangeViewModeMeta): PerformAction<ChangeViewModeMeta> {
    return { meta, type: ReducerActions.ChangeViewMode };
}

// ==================
// Actions
// ==================

export function switchLanguage(meta: SwitchLanguageMeta): PerformAction<SwitchLanguageMeta> {
    return { type: ReducerActions.SwitchLanguage, meta };
}

