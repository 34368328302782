import  React from "react"

interface Props {
    readonly size?: number
    readonly done: boolean
}

export function SuccessIcon({size = 20, done}:Props) {
    return (
        <svg
            width={size}
            height={size}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zM8 15l-5-5 1.41-1.41L8 12.17l7.59-7.59L17 6l-9 9z"
                fill={done ? "#7CC97A": "#B5B5B5"}
            />
        </svg>
    )
}

