import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {Switch, Theme} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import IconButton from "@mui/material/IconButton";
import WorkflowTrigger from "./WorkflowTrigger";
import {WorkflowActions} from "./WorkflowActions";
import {Loader} from "../ui/Loader";
import {useHistory, useParams} from "react-router";
import {Routes} from "../../constants/Routes";
import {useWorkflowContext} from "../../api/workflow/WorkflowContext";
import SkeletonLoad from "../ui/SkeletonLoad";
import Modal from "../ui/Modal";
import WorkflowUpdateForm from "./WorkflowUpdateForm";
import EditIcon from '@mui/icons-material/Edit';
import {motion} from "framer-motion";
import {pageTransition, pageVariants} from "../../constants/AppConstants";
import {showError} from "../../utils/NotificationUtils";
import NotFound from "./assets/404.png"

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(24)}`,
    },
    workflowNameCard: {
        background: "#FFFFFF",
        borderRadius: theme.typography.pxToRem(8),
        border: `${theme.typography.pxToRem(1)} solid #DEE2F4`,
        padding: `${theme.typography.pxToRem(21)} ${theme.typography.pxToRem(24)}`,
        display: "flex",
        marginBottom: theme.typography.pxToRem(20),
        alignItems: "center",
        justifyContent: "space-between"
    },
    workflowName: {
        marginLeft: theme.typography.pxToRem(26.5),
        font: `${theme.typography.pxToRem(16)} Lato`,
        fontWeight: 700,
    },
    stepsRow: {
        display: "flex",
        gap: theme.typography.pxToRem(24),
        flexWrap: "wrap",
        justifyContent: "center"
    },
    triggerHeader: {
        padding: theme.typography.pxToRem(16),
        borderBottom: "1px solid #e8e8e8",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    triggerHeaderTitleRoot: {
        display: "flex"
    },
    triggerLogo: {
        width: theme.typography.pxToRem(37),
        height: theme.typography.pxToRem(37),
        padding: theme.typography.pxToRem(7),
        marginRight: theme.typography.pxToRem(8),
        borderRadius: theme.typography.pxToRem(8),
        background: "#F6F7FC",
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "contain"
        }
    },
    triggerName: {
        color: "#B5B5B5",
        fontSize: theme.typography.pxToRem(12)
    },
    triggerAppName: {
        fontSize: theme.typography.pxToRem(16)
    },
    triggerInfo: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around"
    }
}));

export enum StepsType {
    ChooseAppEndEvent = "CHOOSE_APP_AND_EVENT",
    ChooseAccount = "CHOOSE_ACCOUNT",
    SetupTrigger = "SETUP_TRIGGER",
    TestTrigger = "TEST_TRIGGER",
    TestAction = "TEST_ACTION",
    SetupAction = "SETUP_ACTION",
}

function WorkflowEditor() {
    const [loaded, setLoaded] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [workflowInfo, setWorkflowInfo] = useState<any>();
    const [showModal, setShowModal] = useState(false);
    const [changingStatus, setChangingStatus] = useState(false);
    const [checked, setChecked] = useState(workflowInfo?.active);

    const classes = useStyles();
    const history = useHistory();
    const params: any = useParams();
    const {WorkflowApi} = useWorkflowContext();

    const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChangingStatus(true);
        if (event.target.checked) {
            WorkflowApi.activateWorkflow({data: {id: workflowInfo?.id}}).then(() => {
                setChecked(true);
                setChangingStatus(false);
            }).catch(err => {
                showError(err);
                setChangingStatus(false)
            })
        } else {
            WorkflowApi.deActivateWorkflow({data: {id: workflowInfo?.id}}).then(() => {
                setChecked(false);
                setChangingStatus(false)
            }).catch(err => {
                showError(err)
                setChangingStatus(false)
            })
        }
    };

    useEffect(() => {
        WorkflowApi.getWorkflowDetails(params.id).then(res => {
            setWorkflowInfo(res.payload)
        }).catch(err => {
            if (err.message === "NOT_FOUND") {
                setNotFound(true)
            }
            showError(err)
        });
        setLoaded(true)
    }, [checked]);


    return (
        <motion.div className={classes.root}
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants as any}
                    transition={pageTransition as any}
        >
            {
                <>
                    <div className={classes.workflowNameCard}>
                        <div className="d-flex">
                            <IconButton onClick={() => history.replace({pathname: Routes.AppDashboard})}>
                                <ArrowBackIosNewIcon/>
                            </IconButton>
                            <IconButton onClick={() => setShowModal(true)} disabled={!workflowInfo}>
                                <EditIcon/>
                            </IconButton>
                            <div>
                                <h2 className={classes.workflowName}>{workflowInfo?.name ||
                                <SkeletonLoad height={30}/>}</h2>
                            </div>
                        </div>
                        {(!changingStatus && workflowInfo) ? <Switch
                            checked={workflowInfo?.active}
                            onChange={handleStatusChange}
                        /> : <Loader/>}
                    </div>
                    {loaded ? (
                            notFound ? (
                                <div>
                                    <img src={NotFound} alt="notFound" width={400}/>
                                </div>
                            ) : (
                                <div className={classes.stepsRow}>
                                    <WorkflowTrigger classesMore={classes}/>
                                    <WorkflowActions classesMore={classes}/>
                                </div>
                            )
                        )
                        : <Loader size={50}/>
                    }
                    <Modal onClose={() => setShowModal(false)} show={showModal}>
                        {workflowInfo && <WorkflowUpdateForm
                            data={{
                                name: workflowInfo?.name,
                                description: workflowInfo?.description,
                                id: workflowInfo?.id
                            }}
                            onChanged={(name: string) => {
                                setWorkflowInfo((prev: any) => ({...prev, name: name}));
                                setShowModal(false)
                            }}/>}
                    </Modal>
                </>
            }
        </motion.div>
    );
}

export default WorkflowEditor;
