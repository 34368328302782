import React, {useCallback, useState} from 'react';
import AppWrapper from "../../components/app/AppWrapper";
import {Divider, IconButton, InputAdornment, TextField} from "@mui/material";
import cx from "classnames"
import {AppButton} from "../../components/ui/AppButton";
import {useI18n} from "../../i18n/I18nContext";
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import {useAuthContext} from "../../api/auth/AuthContext";
import {useFormik} from "formik";
import {object, string} from "yup";
import {Link, useHistory} from "react-router-dom";
import {Routes} from "../../constants/Routes";
import {toast} from "react-toastify";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import AuthWelcome from "../../components/auth/AuthWelcome";

const validationSchema = (translate: any) => object({
    email: string().email(translate("EMAIL_ERROR")).required(translate("REQUIRED")),
    firstName: string().required(translate("REQUIRED")),
    lastName: string().required(translate("REQUIRED")),
    password: string().min(6,translate("PASSWORD_LENGTH")).required(translate("REQUIRED")),
});

interface Props {
    readonly classes: any;
}


export function AuthSignUpContainer({classes}: Props) {
    const {translate} = useI18n();
    const {AuthApi} = useAuthContext();
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);


    const submitHandler = useCallback((values) => {
        AuthApi.signUp({data: values}).then(res => {
            if (res.status === "OK") {
                toast.success(translate("SUCCESSFUL_SIGN_UP"));
                history.replace({pathname: Routes.AuthLogin})
            } else {
                toast.error(res.errors);
            }
        })

    }, [AuthApi, history, translate]);


    const formik = useFormik({
        initialValues: {
            email: '',
            firstName: '',
            lastName: '',
            password: '',
        },
        validationSchema: validationSchema(translate),
        onSubmit: (values) => submitHandler(values)
    });


    return (
        <AppWrapper>
            <div className={classes.root}>
                <div className={classes.signUpBody}>
                    <AuthWelcome classes={classes} />
                    <div className={cx(classes.signUpRight, classes.signUpSide)}>
                        <div>
                            <AppButton className={classes.oAuthBtn} background="#5086EC"
                                       variant="contained"><GoogleIcon/>{translate("SIGN_UP")}{" "}{translate("WITH_GOOGLE")}</AppButton>
                            <AppButton className={classes.oAuthBtn} background="#405A94"
                                       variant="contained"><FacebookIcon/>{translate("SIGN_UP")}{" "}{translate("WITH_FACEBOOK")}
                            </AppButton>
                        </div>
                        <Divider className={classes.divider}>{translate("SIGNUP_WITH_EMAIL")}</Divider>
                        <form onSubmit={formik.handleSubmit}>
                            <TextField fullWidth variant="outlined"
                                       value={formik.values.email}
                                       onChange={formik.handleChange}
                                       error={formik.touched.email && Boolean(formik.errors.email)}
                                       helperText={formik.touched.email && formik.errors.email}
                                       type="text"
                                       label={translate("EMAIL")}
                                       name="email"
                                       id="email"
                                       className={classes.email}
                            />
                            <div className={classes.inputBox}>
                                <TextField fullWidth variant="outlined"
                                           value={formik.values.firstName}
                                           onChange={formik.handleChange}
                                           error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                           helperText={formik.touched.firstName && formik.errors.firstName}
                                           type="text"
                                           label={translate("FIRST_NAME")}
                                           name="firstName"
                                           id="firstName"
                                />
                                <TextField fullWidth variant="outlined"
                                           value={formik.values.lastName}
                                           onChange={formik.handleChange}
                                           error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                           helperText={formik.touched.lastName && formik.errors.lastName}
                                           type="text"
                                           label={translate("LAST_NAME")}
                                           name="lastName"
                                           id="lastName"
                                />
                            </div>
                            <TextField fullWidth variant="outlined"
                                       value={formik.values.password}
                                       onChange={formik.handleChange}
                                       error={formik.touched.password && Boolean(formik.errors.password)}
                                       helperText={formik.touched.password && formik.errors.password}
                                       type={showPassword ? "text" : "password"}
                                       label={translate("PASSWORD")}
                                       name="password"
                                       id="password"
                                       className={classes.email}
                                       InputProps={{
                                           endAdornment: <InputAdornment position="end">
                                               <IconButton
                                                   aria-label="toggle password visibility"
                                                   onClick={() => {setShowPassword(prev => !prev)}}
                                                   edge="end"
                                               >
                                                   {showPassword ? <VisibilityOff /> : <Visibility />}
                                               </IconButton>
                                           </InputAdornment>,
                                       }}
                            />
                            <AppButton type="submit" className={classes.submitBtn}
                                       variant="contained">{translate("SIGN_UP")}</AppButton>
                            <p className={cx(classes.footerInfos, classes.privacyPolicy)}>{translate("PRIVACY_POLICY_ALERT")}</p>
                            <p className={classes.footerInfos}>{translate("ALREADY_HAVE_ACCOUNT")}{" "}<Link
                                to={Routes.AuthLogin}>{translate("SIGN_IN")}</Link></p>
                        </form>
                    </div>
                </div>
            </div>
        </AppWrapper>
    );
}

