import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {Switch, Theme} from "@mui/material";
import {useWorkflowContext} from "../../api/workflow/WorkflowContext";
import {Work} from "@mui/icons-material";
import NotFoundImg from "../workflow/assets/404.png"
import {useHistory, useParams} from "react-router";
import {showError} from "../../utils/NotificationUtils";
import {GetWorkflowDetailsDTO} from "../../api/workflow/WorkflowDTO";
import {motion} from 'framer-motion';
import {pageTransition, pageVariants} from "../../constants/AppConstants";
import IconButton from "@mui/material/IconButton";
import {Routes} from "../../constants/Routes";
import ArrowBackIosNewIcon from "@mui/material/SvgIcon/SvgIcon";
import SkeletonLoad from "../ui/SkeletonLoad";
import {Loader} from "../ui/Loader";
import EditIcon from '@mui/icons-material/Edit';
import WorkflowTriggerV2 from "./trigger/WorkflowTriggerV2";
import {WorkflowActionsV2} from "./action/WorkflowActionsV2";


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(24)}`,
    },
    workflowNameCard: {
        background: "#FFFFFF",
        borderRadius: theme.typography.pxToRem(8),
        border: `${theme.typography.pxToRem(1)} solid #DEE2F4`,
        padding: `${theme.typography.pxToRem(21)} ${theme.typography.pxToRem(24)}`,
        display: "flex",
        marginBottom: theme.typography.pxToRem(20),
        alignItems: "center",
        justifyContent: "space-between"
    },
    workflowName: {
        marginLeft: theme.typography.pxToRem(26.5),
        font: `${theme.typography.pxToRem(16)} Lato`,
        fontWeight: 700,
        textTransform: "capitalize"
    },
    stepsRow: {
        display: "flex",
        gap: theme.typography.pxToRem(24),
        flexWrap: "wrap",
        justifyContent: "center"
    },
    triggerHeader: {
        padding: theme.typography.pxToRem(16),
        borderBottom: "1px solid #e8e8e8",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    triggerHeaderTitleRoot: {
        display: "flex"
    },
    triggerLogo: {
        width: theme.typography.pxToRem(37),
        height: theme.typography.pxToRem(37),
        padding: theme.typography.pxToRem(7),
        marginRight: theme.typography.pxToRem(8),
        borderRadius: theme.typography.pxToRem(8),
        background: "#F6F7FC",
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "contain"
        }
    },
    triggerName: {
        color: "#B5B5B5",
        fontSize: theme.typography.pxToRem(12)
    },
    triggerAppName: {
        fontSize: theme.typography.pxToRem(16)
    },
    triggerInfo: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around"
    },
    loader: {
        maxWidth: "58px"
    }
}));

export enum StepsTypeV2 {
    ChooseAppEndEvent = "CHOOSE_APP_AND_EVENT",
    ChooseAccount = "CHOOSE_ACCOUNT",
    SetupTrigger = "SETUP_TRIGGER",
    TestTrigger = "TEST_TRIGGER",
    TestAction = "TEST_ACTION",
    SetupAction = "SETUP_ACTION",
}

function WorkflowEditorV2() {
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(true);
    const [didNotFound, setDidNotFound] = useState<boolean>(false);
    const [workflowData, setWorkflowData] = useState<GetWorkflowDetailsDTO>({} as GetWorkflowDetailsDTO);
    const [actions, setActions] = useState<any>([]);
    const [isWorkflowActive, setIsWorkflowActive] = useState(false);
    const [changingStatus, setChangingStatus] = useState(false);
    const {WorkflowApi} = useWorkflowContext();
    const params: any = useParams();
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);

    const handleStatusChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setChangingStatus(true);
            WorkflowApi[isWorkflowActive ? "deActivateWorkflow" : "activateWorkflow"](
                {
                    data: {id: workflowData.id},
                }
            )
                .then(() => {
                    setIsWorkflowActive(!isWorkflowActive);
                    setChangingStatus(false);
                    setLoading(false)
                })
                .catch((err) => {
                    showError(err);
                    setLoading(false);
                    setChangingStatus(false);
                });
        },
        [workflowData]
    );


    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            WorkflowApi.getWorkflowDetails(params.id).then(res => {
                setWorkflowData(res.payload);
                setActions(() => {
                    let data: any = [];
                    res.payload?.actions?.map((x: any) => {
                            if (x) {
                                data.push(
                                    {
                                        "accountId": x.account?.id ?? null,
                                        "appId": x.app?.id ?? null,
                                        "eventId": x.event?.id ?? null,
                                        "mappingSource": x.mappingSource ?? null,
                                        "previousActionId": x.previousAction?.id ?? null
                                    }
                                )
                            }
                        }
                    );

                    return data
                })
                if (res.payload.active) {
                    setIsWorkflowActive(true);
                }
            }).catch(err => {
                if (err.message === "NOT_FOUND") {
                    setDidNotFound(true)
                }
                showError(err)
            })
        }

        return () => {
            isMounted = false
        }
    }, []);

    if (didNotFound) {
        return <img src={NotFoundImg} alt="404"/>
    }

    return (
        <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants as any}
            transition={pageTransition as any}
            className={classes.root}
        >
            {
                !loading ? (
                    <Loader/>
                ) : (
                    <>
                        <div className={classes.workflowNameCard}>
                            <div className="d-flex">
                                <IconButton onClick={() => history.replace({pathname: Routes.AppDashboard})}>
                                    <ArrowBackIosNewIcon/>
                                </IconButton>
                                <IconButton onClick={() => setShowModal(true)} disabled={!workflowData}>
                                    <EditIcon/>
                                </IconButton>
                                <div>
                                    <h2 className={classes.workflowName}>{workflowData.name ||
                                    <SkeletonLoad height={30}/>}</h2>
                                </div>
                            </div>
                            {
                                !changingStatus ? (
                                    <Switch
                                        checked={isWorkflowActive}
                                        onChange={handleStatusChange}
                                    />
                                ) : (
                                    <Loader rootClassName={classes.loader}/>
                                )
                            }
                        </div>
                        <div className={classes.stepsRow}>
                        <WorkflowTriggerV2 data={workflowData.trigger ? {
                            accountId: workflowData.trigger.account?.id ?? null,
                            appId: workflowData.trigger.app?.id ?? null,
                            eventId: workflowData.trigger.event?.id ?? null,
                            inputData: {}
                        } : {}} classesMore={classes}/>
                        <WorkflowActionsV2 classesMore={classes} actions={actions}/>
                        </div>
                    </>
                )
            }
        </motion.div>
    );


}

export default WorkflowEditorV2;