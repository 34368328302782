import React, {useState} from 'react';
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {Form, Formik} from "formik";
import {WorkflowTriggerStepsWrapper} from "../steps/WorkflowTriggerStepsWrapper";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        maxWidth: theme.typography.pxToRem(443),
        minWidth: theme.typography.pxToRem(300),
        width: "100%",
        background: "#fff",
        boxShadow: "0px 14px 24px #C6C8D7",
        borderRadius: "16px",
    },
}));

interface Props {
    readonly data: any
    readonly classesMore: any
}

function WorkflowTriggerV2({data,classesMore}:Props) {
    const classes = useStyles();
    const [stepInfo, setStepInfo] = useState<any>();


    return (
        <>
            <div className={classes.root}>
                <div className={classesMore.triggerHeader}>
                    <div className={classesMore.triggerHeaderTitleRoot}>
                        <div className={classesMore.triggerLogo}>
                            <img src={stepInfo?.logoUrl ?? "https://reactjs.org/favicon.ico"} alt="logo"/>
                        </div>
                        <div className={classesMore.triggerInfo}>
                            <p className={classesMore.triggerName}>Trigger</p>
                            <h2 className={classesMore.triggerAppName}>{stepInfo?.name ?? "Application Name"}</h2>
                        </div>
                    </div>
                </div>
                <Formik onSubmit={(values) => console.log(values)} enableReinitialize={true} initialValues={data}>
                    {({values, setFieldValue}) => {
                        console.log(values)
                        return (
                        <Form>
                            <WorkflowTriggerStepsWrapper stepInfo={stepInfo} onSetStepInfo={(info: any) => setStepInfo(info)} data={values} setFieldValue={setFieldValue} />
                        </Form>
                    )}}
                </Formik>
            </div>
        </>
    );
}

export default WorkflowTriggerV2;