import {Routes} from "./Routes";
import {Dashboard} from "@mui/icons-material";
import LanguageIcon from '@mui/icons-material/Language';
import HelpIcon from '@mui/icons-material/Help';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import AppsIcon from '@mui/icons-material/Apps';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

export const Menu = [
    {
        title: "DASHBOARD",
        link: Routes.AppDashboard,
        icon: Dashboard
    },
    {
        title: "My apps",
        link: Routes.AppMyApps,
        icon: AppsIcon
    },
    {
        title: "Workflow history",
        link: Routes.AppWorkflowHistory,
        icon: WatchLaterIcon
    },
    {
        title: "Explore",
        link: Routes.AppExplore,
        icon: LanguageIcon
    },
    {
        title: "Get help",
        link: Routes.AppGetHelp,
        icon: HelpIcon
    },
];

export const pageVariants = {
    initial: {
        opacity: 0,
        marginLeft: "100%",
    },
    in: {
        opacity: 1,
        marginLeft: 0,
    },
    out: {
        opacity: 0,
        marginLeft: "-100%",
    }
};

export const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5
};

export interface SelectOptions {
    readonly label: string,
    readonly value: string
}
