import React, {useEffect, useState} from "react";
import {WorkflowListItem} from "./WorkflowListItem";
import {makeStyles} from "@mui/styles";
import {useWorkflowContext} from "../../api/workflow/WorkflowContext";
import {GetWorkflowListProps} from "../../api/workflow/WorkflowDTO";
import {Loader} from "../ui/Loader";
import {EmptyList} from "../ui/EmptyList";
import {Theme} from "@mui/material";
import {AppField} from "../ui/AppField";
import {AnimatePresence, AnimateSharedLayout, motion} from "framer-motion";
import {useI18n} from "../../i18n/I18nContext";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: "16px",
    },
    actionsRoot: {
        maxWidth: theme.typography.pxToRem(676),
        width: "100%",
        display: "flex",
        gap: "20px",
        justifyContent: "space-between"
    },
    actionsSearch: {
        flex: "1 1"
    },
    notFoundName: {
        maxWidth: 150,
        display: "inline-block",
        textOverflow: "ellipsis",
        overflow: "hidden",
    }
}));

export function WorkflowListing() {
    const classes = useStyles();
    const {translate} = useI18n();
    const {WorkflowApi} = useWorkflowContext();
    const [loaded, setLoaded] = useState(false);
    const [list, setList] = useState<GetWorkflowListProps[]>([]);
    const [searchText, setSearchText] = useState<string>("");

    useEffect(() => {
        WorkflowApi.getWorkflowList().then((res: any) => {
            setList(res["payload"]);
            setLoaded(true);
        });
    }, [searchText]);

    return (
        <div className={classes.root}>
            <div className={classes.actionsRoot}>
                <div className={classes.actionsSearch}>
                    <AppField name="search" placeholder={translate("SEARCH_HERE")} onChange={(e: any) => setSearchText(e.target.value)} autoComplete="off"/>
                </div>
            </div>
            <AnimateSharedLayout>
                <AnimatePresence initial={false}>
            {loaded ? (
                list?.length > 0 ? (
                    list.filter(x => x.name.toLowerCase().includes(searchText.toLowerCase()))?.length > 0 ?
                        list.filter(x => x.name.toLowerCase().includes(searchText.toLowerCase()))?.map((item) => {
                        return  <WorkflowListItem data={item} key={item.id}/>;
                    }) : (
                        <motion.p layout key={Math.random() }>{translate("WORKFLOW_WITH_NAME")} "<b title={searchText} className={classes.notFoundName}>{searchText}</b>" {translate("WAS_NOT_FOUND")}</motion.p>
                        )
                ) : (
                    <EmptyList/>
                )
            ) : (
                <Loader size={50}/>
            )}
                </AnimatePresence>
            </AnimateSharedLayout>
        </div>
    );
}
