import React, {useState} from 'react';
import Empty from "../workflow/assets/empty.png";
import {makeStyles} from "@mui/styles";
import Modal from "./Modal";
import WorkflowCreateForm from "../workflow/WorkflowCreateForm";

const useStyles = makeStyles({
    root: {
        textAlign: "center",
        maxWidth: "300px",
        "& .createWorkflow": {
            color: "blue",
            cursor: "pointer",
            textDecoration: "underline"
        }
    },
    titleRoot: {
        marginTop: "20px",
        "& p": {
            fontSize: "14px"
        }
    }
});

export function EmptyList() {
    const classes = useStyles();
    const [createWorkflowModal, setCreateWorkflowModal] = useState(false);

    return (
        <div className={classes.root}>
            <div>
                <img src={Empty} alt=""/>
            </div>
            <div className={classes.titleRoot}>
                <h2>Empty</h2>
                <p>Click <span title="Create Workflow" className="createWorkflow" onClick={() => setCreateWorkflowModal(true)}> here</span> to create a new workflow
                </p>
            </div>
            <Modal show={createWorkflowModal} onClose={() => setCreateWorkflowModal(false)}>
                <WorkflowCreateForm onCreated={() => setCreateWorkflowModal(false)} />
            </Modal>
        </div>
    );
}

