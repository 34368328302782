import React, { useCallback, useState } from "react";
import { AppButton } from "../ui/AppButton";
import { makeStyles } from "@mui/styles";
import { Alert, Theme } from "@mui/material";
import { useWorkflowContext } from "../../api/workflow/WorkflowContext";
import { useParams } from "react-router";
import { StepsType } from "./workflowEditor";
import { useI18n } from "../../i18n/I18nContext";
import {
    objToFieldsArray,
} from "../../helpers/objectHelpers";
import { showError } from "../../utils/NotificationUtils";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme: Theme) => ({
    logoRoot: {
        display: "flex",
        justifyContent: "center",
        marginBottom: theme.typography.pxToRem(16),
    },
    footer: {
        textAlign: "center",
        fontSize: theme.typography.pxToRem(14),
    },
    logo: {
        width: theme.typography.pxToRem(80),
        height: theme.typography.pxToRem(80),
        padding: theme.typography.pxToRem(5),
        borderRadius: theme.typography.pxToRem(10),
        border: "2px solid #e8e8e8",
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "contain",
        },
    },
    testDataList: {
        maxHeight: theme.typography.pxToRem(300),
        overflow: "auto",
    },
    testData: {
        background: "#FBFBFB",
        borderRadius: "16px",
        padding: "16px",
        fontSize: "12px",
    },
    testDataRow: {
        display: "flex",
        gap: "6px",
    },
    error: {
        borderRadius: theme.typography.pxToRem(10),
        border: "1px solid #ff5f5d",
        background: "#ffe9f3",
        padding: theme.typography.pxToRem(12),
        color: "#ff5f5d",
        marginBottom: theme.typography.pxToRem(16),
    },
    errorDetails: {
        fontSize: theme.typography.pxToRem(13),
    },
    testPassed: {
        border: "1px solid #60FF67",
        margin: theme.typography.pxToRem(10) + " 0",
        padding: 10,
        background: "#ebffee",
        borderRadius: 10,
    },
}));

interface Props {
    readonly data: any;
    readonly onDone: (type: StepsType) => void;
    readonly isTrigger: boolean;
    readonly stepInfo: any;
}

export function WorkflowSaveAndTest({
                                        data,
                                        onDone,
                                        isTrigger,
                                        stepInfo,
                                    }: Props) {
    const classes = useStyles();
    const params: any = useParams();
    const [loading, setLoading] = useState(false);
    const { WorkflowApi } = useWorkflowContext();
    const { translate } = useI18n();
    const [testData, setTestData] = useState<any | null>(null);
    const [error, setError] = useState<any | null>(null);
    const onSaveAndTestTrigger = useCallback(() => {
        setLoading(true);
        WorkflowApi.configureTrigger(params.id, data)
            .then(() => {
                WorkflowApi.testTrigger(params.id)
                    .then((r) => {
                        setLoading(false);
                        setTestData(r.payload[0]);
                        onDone(StepsType.TestTrigger);
                    })
                    .catch((err) => {
                        setLoading(false);
                        setError({
                            message: err.message,
                        });
                    });
            })
            .catch((err) => {
                showError(err);
                setLoading(false);
            });
    }, [data, WorkflowApi, onDone, params.id, isTrigger]);

    const onSave = useCallback(() => {
        setLoading(true);
        WorkflowApi.configureTrigger(params.id, data)
            .then(() => {
                setLoading(false);
                toast.success("SAVED");
                onDone(StepsType.TestTrigger);
            })
            .catch((err) => {
                showError(err);
                setLoading(false);
            });
    }, [data, WorkflowApi, onDone, params.id, isTrigger]);

    return (
        <div>
            {error && (
                <Alert sx={{ mb: 1 }} severity="error">
                    {error.message}
                </Alert>
            )}
            {testData && (
                <div className={classes.testData}>
                    <ul className={classes.testDataList}>
                        {objToFieldsArray(testData)?.map((x) => {
                            return (
                                <li className={classes.testDataRow} key={x.id}>
                                    {x.parentObj && <strong>{x.parentObj}</strong>}{" "}
                                    <strong>{x.label}:</strong>
                                    <span>{x.value}</span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
            {!testData && !error && (
                <div>
                    <div className={classes.logoRoot}>
                        <div className={classes.logo}>
                            <img src={stepInfo.logo} alt="logo" />
                        </div>
                    </div>
                    <div className={classes.footer}>
                        <p>{translate("WORKFLOW_TEST_TRIGGER_TITLE")}</p>
                        <div>
                            <AppButton
                                loading={loading}
                                disabled={loading}
                                onClick={onSave}
                                sx={{ my: 2, mr: 2 }}
                            >
                                {translate("SAVE")}
                            </AppButton>
                            <AppButton
                                loading={loading}
                                disabled={loading}
                                onClick={onSaveAndTestTrigger}
                                sx={{ my: 2 }}
                            >
                                {translate("SAVE")} & {translate("TEST_TRIGGER")}
                            </AppButton>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
