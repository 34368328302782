import { isArray } from "lodash";

import { AppError } from "../helpers/AppError";
import {toast} from "react-toastify";

export function showError(error: AppError): void {
  const errors = error.getErrors();

  if (isArray(errors)) {
    errors.forEach((error) => {
      toast.error(error)
    });
  }
}
