import React from 'react';
import inProgressImg from "./assets/inProgress.png"
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center",
        padding: "20px",
        flexDirection: "column"
    },
    img: {
        maxWidth: 500,
        borderRadius: 20,
        marginBottom: 10
    }
}))

export function WorkInProgress() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <img  className={classes.img} src={inProgressImg} alt=""/>
            <h1>Work In Progress</h1>
        </div>
    );
}

