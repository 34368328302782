import React, {ReactNode} from 'react';
import {makeStyles} from "@mui/styles";
import {hexToRgba} from "../../utils/FormatUtils";
import cx from "classnames"
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(() => ({
    root: {
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 1000,
        transform: "translateY(-130%)",
        transition: "0.3s",
        "&.show": {
            transform: "translateY(0)",
        }
    },
    content: {
        minWidth: "545px",
        padding: "36px 45px",
        borderRadius: "20px",
        background: "#fff",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)"
    },
    overflow: {
        background: hexToRgba("#000", {opacity: 0.8}),
        width: "100%",
        height: "100%",
    },
    close: {
        position: "absolute",
        top: "10px",
        right: "10px",
        cursor: "pointer",
        width: "24px",
        height: "24px",
        background: "#E5E5E5",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    contentBody: {
        maxHeight: "70vh",
        overflow: "auto",
    }
}));

interface Props {
    readonly show: boolean
    readonly onClose: () => void
    readonly children: ReactNode
}

function Modal({show, onClose, children}: Props) {

    const classes = useStyles();
    return (
        <div className={cx(classes.root, {show})} >
            <div className={classes.overflow} onClick={() => onClose()}/>
            <div className={classes.content}>
                <div className={classes.close}><CloseIcon sx={{fontSize: 15}} onClick={() => onClose()}/></div>
                <div className={classes.contentBody}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Modal;
