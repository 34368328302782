import React from 'react';
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {WorkflowListing} from "../components/workflow/WorkflowListing";
import {motion} from "framer-motion";
import {pageTransition, pageVariants} from "../constants/AppConstants";

const useStyles = makeStyles((theme: Theme)=> ({
    root: {
        padding: `${theme.typography.pxToRem(32) } ${theme.typography.pxToRem(24) }`,

    }
}));


function DashboardContainer() {

    const classes = useStyles();
    return (
        <motion.div className={classes.root}
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants as any}
                    transition={pageTransition as any}
        >
        <WorkflowListing />
     </motion.div>
    );
}

export default DashboardContainer;