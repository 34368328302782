import {I18nSchema} from "./I18nSchema";

export const I18nEnglish: I18nSchema = {
    "WELCOME": "Welcome!",
    "LOGIN_FORM_TITLE": "Join The Community",
    "EMAIL": "E-Mail",
    "PASSWORD": "Password",
    "SIGN_IN": "Sign In",
    "SIGN_UP": "Sign Up",
    "SIGN_IN_NO_ACCOUNT": "No account?",
    "SIGN_IN_NO_ACCOUNT_CREATE_ONE": "Create one!",
    "FIRST_NAME": "First Name",
    "LAST_NAME": "Last Name",
    "ALREADY_HAVE_ACCOUNT": "Already have an account?",
    "REQUIRED": "Required",
    "EMAIL_ERROR": "Email must be valid",
    "WRONG_CREDENTIALS": "The username or password is incorrect",
    "SUCCESSFUL_LOGIN": "Successful login",
    "SUCCESSFUL_SIGN_UP": "Account created successfully!",
    "BUILD_INTEGRATION": "Create integration",
    "START_INTEGRATION": "Start integration",
    "LOGOUT": "Logout",
    "NAME": "Name",
    "HOME_PAGE": "Home page",
    "VERSION": "Version",
    "STATUS": "Status",
    "ACTIONS": "Actions",
    "INTEGRATIONS": "Integrations",
    "EMPTY_LIST": "Empty List",
    "MAIN_MENU": "Main Menu",
    "DESCRIPTION": "Description",
    "CANCEL": "Cancel",
    "CREATE": "Create",
    "DELETED": "Deleted",
    "WITH_GOOGLE": "with Google",
    "WITH_FACEBOOK": "with Facebook",
    "SIGNUP_WITH_EMAIL": "or sign up with email",
    "SIGNIN_WITH_EMAIL": "or sign in with email",
    "PRIVACY_POLICY_ALERT": "By signing up, you agree to Tact’s Terms of Service and Privacy Policy.",
    "PASSWORD_LENGTH": "password must be at least 6 characters",
    "AUTOMATE": "AUTOMATE",
    "YOUR": "YOUR",
    "TASKS": "tasks",
    "WITH_US": "WITH US",
    "DASHBOARD": "Dashboard",
    "APPLICATIONS": "Applications",
    "CHOOSE_APP_AND_EVENT": "Choose app & event",
    "CHOOSE_ACCOUNT": "Choose account",
    "SETUP_TRIGGER": "Setup trigger",
    "SETUP_ACTION": "Setup action",
    "TEST_TRIGGER": "Test trigger",
    "TEST_ACTION": "Test action",
    "NAME_YOUR_WORKFLOW": "Name Your workflow",
    "CREATE_WORKFLOW": "Create Workflow",
    "CONTINUE": "Continue",
    "EMPTY": "Empty",
    "WORKFLOW_TEST_TRIGGER_TITLE": "We will check that the correct account is connected and your trigger is configured correctly.",
    "UPDATE": "Update",
    "DISABLED": "Disabled",
    "ACTIVE": "Active",
    "CHANGE_APP": "Change app",
    "CURRENT_APP": "Current app",
    "CHOOSE_TRIGGER_EVENT": "Choose trigger event",
    "CHOOSE_ACTION_EVENT": "Choose action event",
    "COPY_ACTION_ID": "Copy action ID",
    "COPIED_TO_CLIPBOARD": "Copied to clipboard",
    "PLEASE_SAVE_CHANGES": "Please save changes",
    "SEARCH_HERE": "Search here",
    "WORKFLOW_WITH_NAME": "Workflow with name:",
    "WAS_NOT_FOUND": "was not found",
};
