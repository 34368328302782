import React from 'react';
import ReactDOM from 'react-dom';
import {AppContainer} from "./containers/AppContainer";
import "./styles/global.css"

ReactDOM.render(
  <React.StrictMode>
    <AppContainer />
  </React.StrictMode>,
  document.getElementById('root')
);
