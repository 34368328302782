import React, {Suspense} from 'react';
import {Route, Switch, useLocation} from "react-router";
import {Routes} from "../constants/Routes";
import DashboardContainer from "./DashboardContainer";
import AppWrapper from "../components/app/AppWrapper";
import WorkflowEditor from "../components/workflow/workflowEditor";
import {AnimatePresence} from "framer-motion";
import {WorkInProgress} from "../components/ui/WorkInProgress";
import WorkflowEditorV2 from "../components/workflow-v2/WorkflowEditorV2";

export function AppRootContainer() {
    const location = useLocation();

    return (
        <Suspense
            fallback={() => (
                <div className="pre-loader">
                    <h1>Loading...</h1>
                </div>
            )}
        >
            <AppWrapper>
                <AnimatePresence exitBeforeEnter>
                    <Switch location={location} key={location.pathname}>
                        <Route path={Routes.CreateWorkflow} component={WorkflowEditor}/>
                        <Route path={Routes.CreateWorkflowV2} component={WorkflowEditorV2}/>
                        <Route path={Routes.AppDashboard} component={DashboardContainer}/>
                        <Route path={Routes.AppMyApps} component={WorkInProgress}/>
                        <Route path={Routes.AppWorkflowHistory} component={WorkInProgress}/>
                        <Route path={Routes.AppExplore} component={WorkInProgress}/>
                        <Route path={Routes.AppGetHelp} component={WorkInProgress}/>
                    </Switch>
                </AnimatePresence>
            </AppWrapper>
        </Suspense>
    );
}

