import React, {useState} from 'react';
import ReactJson from "react-json-view";
import {makeStyles} from "@mui/styles";
import {log} from "util";
import {useI18n} from "../../../i18n/I18nContext";
import {WorkflowEditorTriggerDTO} from "../../../api/workflow/WorkflowDTO";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import StepContent from "@mui/material/StepContent/StepContent";
import {useWorkflowContext} from "../../../api/workflow/WorkflowContext";
import {useParams} from "react-router";
import cx from "classnames"

const useStyles = makeStyles(() => ({
    jsonViewer: {
        padding: 15,
        background: "#f3f3f3",
        border: "2px solid #4ABC55",
        borderRadius: 10,
        "& .object-key": {
            fontWeight: 700
        },

        "&.error": {
            background: "#ffecee",
            borderColor: "#ff3d47"
        }
    },
    infoRoot: {
        display: "flex",
        gap: 15,
        marginBottom: 15
    },
    infoImgRoot: {
        minWidth: 50,
        width: 50,
        height: 50,
        borderRadius: 10,
        background: "#f1f1f1",
        padding: 5,
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "contain"
        }
    },
    infoRootTypo: {
        fontSize: 14,
        fontWeight: 600
    }
}));

interface Props {
    readonly data: WorkflowEditorTriggerDTO
    readonly stepInfo: any
    readonly onSetFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

export function WorkflowTriggerTestV2({stepInfo,data,onSetFieldValue}:Props) {
    const classes = useStyles();
    const { translate } = useI18n();
    const [inputData, setInputData] = useState<any>(null);
    const [error, setError] = useState<any>(null);
    const { WorkflowApi } = useWorkflowContext();
    const params: any = useParams();

    const runTest = () => {
        WorkflowApi.configureTrigger(params.id, data).then(() => {
            WorkflowApi.testTrigger(params.id).then(res => {
                setInputData(res)
                onSetFieldValue("inputData", {})
            }).catch(err => {
                setError(err)
                onSetFieldValue("inputData", {})
            })
        })
    };

    return (
        <div>
            {!Boolean(inputData || error) ? (
                <div className={classes.infoRoot}>
                    <div className={classes.infoImgRoot}>
                        <img src={stepInfo?.logoUrl ?? "#"} alt=""/>
                    </div>
                    <p className={classes.infoRootTypo}>{translate("WORKFLOW_TEST_TRIGGER_TITLE")}</p>
                </div>
            ) : (
                <div className={cx(classes.jsonViewer, {error})}>
                    <ReactJson src={inputData ?? error} displayDataTypes={false} iconStyle="circle"  />
                </div>
            )}
            <Box sx={{ my: 2, textAlign: "right" }}>
                <Button
                    variant="contained"
                    sx={{mr: 1}}
                    onClick={runTest}
                >
                    Finish & Test
                </Button>
                <Button
                    variant="contained"
                    type="submit"
                >
                    Finish
                </Button>

            </Box>

        </div>
    );
}

