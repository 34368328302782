import React, {useEffect, useState} from 'react';
import {AppAutoComplete} from "../../ui/AppAutoComplete";
import {IconButton, TextField} from "@mui/material";
import {useI18n} from "../../../i18n/I18nContext";
import {useTriggerContext} from "../../../api/trigger/TriggerContext";
import {useIntegrationsContext} from "../../../api/integrations/IntegrationsContext";
import {Loader} from "../../ui/Loader";
import {WorkflowEditorTriggerDTO} from "../../../api/workflow/WorkflowDTO";
import Box from "@mui/material/Box";
import {makeStyles} from "@mui/styles";
import {getArrayItemByFieldValue} from "../../../helpers/objectHelpers";
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import {showError} from "../../../utils/NotificationUtils";
import _ from 'lodash';
import cx from 'classnames'

const useStyles = makeStyles(() => ({
    currentAppWrapper: {
        border: "2px solid #E8E8E8",
        borderRadius: "5px",
        padding: "5px",
        display: "flex",
        alignItems: "center",
        flex: "1 1",
        gap: 10,
    },
    currentAppLogo: {
        width: 40,
        height: 40,
        borderRadius: "4px",
        background: "#f1f1f1",
        padding: 4,
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "contain",
        }
    },
    currentAppName: {
        fontWeight: "bold"
    },

    currentAppRoot: {
        display: "flex",
        position: "relative",
        gap: 15
    },
    eventSelectorRoot: {
        maxHeight: "0px",
        height: "auto",
        transition: "0.3s",
        overflow: "hidden",
        "&.show": {
            maxHeight: "100px",
            paddingTop: 10
        }
    },
    currentAppChange: {
        width: 54,
        height: 54,
        border: "2px solid #E8E8E8",
        borderRadius: "0 5px 5px 0",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "0.3s",
        position: "absolute",
        right: 0,
        top: 0,
        "& .MuiSvgIcon-root": {
            transition: "0.4s",
        },

        "&:hover .MuiSvgIcon-root": {
            transform: "rotate(360deg)"
        }
    }
}));

interface Props {
    readonly onSetFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
    readonly data: WorkflowEditorTriggerDTO
    readonly isTrigger: boolean
    readonly onSetStepInfo: (info: any) => void,
    readonly stepInfo: any
    readonly fieldName: string[]
}


export function WorkflowAppSelectorV2({data,fieldName, onSetFieldValue, isTrigger, onSetStepInfo, stepInfo}: Props) {
    const [loading, setLoading] = useState(true);
    const [integrations, setIntegrations] = useState<any>([]);
    const [loadEvents, setLoadEvents] = useState<boolean>(false);
    const [integrationOptions, setIntegrationOptions] = useState<any>([]);
    const [events, setEvents] = useState<any>([]);
    const classes = useStyles();
    const {translate} = useI18n();
    const {TriggerApi} = useTriggerContext();
    const {IntegrationsApi} = useIntegrationsContext();
    useEffect(() => {
        IntegrationsApi.getIntegrationsList().then(res => {
            setIntegrations(res.payload.list);
            let data: any = [];
            res.payload?.list?.map((x: any) =>
                data.push({label: x.name, value: x.id, logo: x.logoUrl})
            );
            setIntegrationOptions(data);
            setLoading(false)
        })
    }, []);

    useEffect(() => {
        if (data.appId) {
            let integrationItem = getArrayItemByFieldValue(integrations, data.appId, "id");

            if (integrationItem && !Boolean(_.isEqual(integrationItem, stepInfo))) {
                onSetStepInfo(integrationItem);
            }
            setLoadEvents(true);
            TriggerApi.getEventList(`/${isTrigger ? "trigger" : "action"}/list/:integrationId`, data.appId).then(res => {

                let data: any = [];
                res.payload?.list?.map((x: any) =>
                    data.push({label: x.name, value: x.id})
                );
                setEvents(data);
                setLoadEvents(false);
            }).catch(err => {
                showError(err);
                setLoadEvents(false);
            })


        }
    }, [data, integrations, stepInfo]);
    console.log(data)

    return (
        <div>
                    <Box sx={{mb: 3}}>
                        <AppAutoComplete
                            label={translate("Choose Trigger Application")}
                            options={integrationOptions}
                            value={data?.appId}
                            required
                            loadingOptions={loading}
                            onValueChange={(value) => {
                                onSetFieldValue(fieldName[0], value);
                                onSetFieldValue("accountId", null);
                                onSetFieldValue("eventId", null);
                            }}
                            renderOption={(props: any, option: any) => (
                                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                    <img
                                        loading="lazy"
                                        width="20"
                                        src={option.logo}
                                        srcSet={option.logo}
                                        alt="logo"
                                    />
                                    {option.label}
                                </Box>
                            )}
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    name="eventId"
                                    variant="outlined"
                                />
                            )}
                        />
                    </Box>

                    <AppAutoComplete
                        label={translate("CHOOSE_TRIGGER_EVENT")}
                        loadingOptions={loadEvents}
                        options={events}
                        disabled={!data?.appId || loadEvents}
                        value={!loadEvents && data?.eventId}
                        title={!data?.appId ? "First you must select a application" : ""}
                        required
                        // inputValue={loadEvents && "Loading..."}
                        onValueChange={(value) => onSetFieldValue("eventId", value)}
                        renderInput={(params: any) => (
                            <TextField
                                {...params}
                                fullWidth
                                name="eventId"
                                variant="outlined"
                            />
                        )}
                    />
        </div>
    );
}

