import {useMemo} from "react";

import {WorkflowApi} from "./WorkflowApi";
import {useApiBase} from "../ApiContext";

export interface WorkflowContextProps {
    readonly WorkflowApi: WorkflowApi;
}

export function useWorkflowContext(): WorkflowContextProps {
    const data = useApiBase();

    const api = useMemo(() => new WorkflowApi(data), [data]);

    return {
        WorkflowApi: api,
    };
}
