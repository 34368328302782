import React, {useState} from 'react';
import "codemirror/lib/codemirror.css"
import "codemirror/theme/dracula.css"
import "codemirror/theme/eclipse.css"
import "codemirror/mode/javascript/javascript"
import {Controlled} from "react-codemirror2"
import {makeStyles} from "@mui/styles";
import {IconButton} from "@mui/material";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import Brightness6Icon from '@mui/icons-material/Brightness6';
interface Props {
    readonly onChange: (editor: any, data: any, value: any) => void
    readonly value: string
}

interface StyleProps {
    readonly fullscreen: boolean
    readonly theme: boolean
}

const useStyles = makeStyles(() => ({
    root: {
        padding: `8px`,
        transition: "0.4s",
        borderRadius: (props: StyleProps) => props.fullscreen ? "0": "10px",
        background: (props: StyleProps) => props.theme ? "#d9d9d9" : "#1b1c26",
        zIndex: (props: StyleProps) => props.fullscreen ? 1000: 1,
        width: (props: StyleProps) => props.fullscreen ? "100vw": "auto",
        height: (props: StyleProps) => props.fullscreen ? "100vh": "auto",
        position: (props: StyleProps) => props.fullscreen ? "fixed": "static",
        top: 0,
        left: 0,
        display: "flex",
        flexDirection: "column"
    },
    actions: {
        marginBottom: "4px"
    },
    actionBtn: {
        color: "#889285 !important"
    },
    editor: {
        flex: "1 1",
        overflow: "hidden",
        paddingBottom: "10px",
        "& .CodeMirror-wrap": {
            height: "100%",
            transition: "0.4s",
            borderRadius: 10
        }
    }
}));


export function CodeEditor({onChange,value}: Props) {
    const [fullScreen, setFullScreen] = useState(false);
    const [theme, setTheme] = useState(false);
    const classes = useStyles({fullscreen: fullScreen, theme: theme});

    return (
      <div className={classes.root} >
          <div className={classes.actions}>
              <IconButton className={classes.actionBtn} onClick={() => {
                  setFullScreen(prev => !prev);
              }}>
                  {!fullScreen ? <OpenInFullIcon color="inherit" /> : <CloseFullscreenIcon color="inherit" />}
              </IconButton>
              <IconButton className={classes.actionBtn} onClick={() => {
                  setTheme(prev => !prev);
              }}>
                  <Brightness6Icon color="inherit" />
              </IconButton>
          </div>
          <Controlled
              onBeforeChange={onChange}
              value={value}
              className={classes.editor}

              options={{
                  lineWrapping: true,
                  lang: "javascript",
                  mode: "javascript",
                  theme: theme ? "eclipse" : "dracula",
                  lineNumbers: true,
                  gutters: ["CodeMirror-lint-markers"],
              }}
          />
      </div>
    );
}

