import React, {useState} from "react";
import {makeStyles} from "@mui/styles";
import {SuccessIcon} from "../icons/SuccessIcon";
import {AppButton} from "../ui/AppButton";
import {useI18n} from "../../i18n/I18nContext";
import {Theme} from "@mui/material";
import {StepsType} from "./workflowEditor";
import {useWorkflowContext} from "../../api/workflow/WorkflowContext";
import {useParams} from "react-router";
import {showError} from "../../utils/NotificationUtils";
import {toast} from "react-toastify";
import {AppField} from "../ui/AppField";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: "0 24px",
        marginTop: "-1px"
    },
    open: {
        padding: "5px 0 16px",
    },
    labelRoot: {
        display: "flex",
        padding: "16px 0",
        borderTop: "1px solid #e8e8e8",
        cursor: "pointer"
    },
    label: {
        font: `${theme.typography.pxToRem(16)}`,
        fontWeight: 700,
        color: "#000",
        display: "flex",
        alignItems: "center",
        marginRight: theme.typography.pxToRem(10)
    },
    webHookUrl: {
        "& .MuiOutlinedInput-input": {
            cursor: "pointer",
        }
    }
}));


interface Props {
    readonly handleChange: (name: any, value: any) => void;
    readonly onDone: (type: StepsType) => void;
    readonly onChangeVisible?: (type: StepsType, value: boolean) => void;
    readonly data: any;
    readonly stepInfo: any;
    readonly steps: any[];
    readonly trigger?: boolean
    readonly webHookUrl?: string | null
    readonly onSetWebHookUrl?: (url: string) =>  void
}

export function WorkflowSteps({
                                  handleChange,
                                  onDone,
                                  steps,
                                  onChangeVisible,
                                  onSetWebHookUrl,
                                  webHookUrl,
                                  stepInfo,
                                  data,
                                  trigger = false
                              }: Props) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState<number>(1);
    const [loading, setLoading] = useState(false);
    const [changed, setChanged] = useState(false);
    const {translate} = useI18n();
    const {WorkflowApi} = useWorkflowContext();
    const params: any = useParams();
    const isWebHook = data?.appId === 10019;

    const handleContinue = (x: any) => {
        if (trigger && isWebHook) {
            setLoading(true);
            WorkflowApi.configureTrigger(params.id, data)
                .then((res) => {
                    setLoading(false);
                    toast.success("SAVED");
                    onDone(StepsType.ChooseAppEndEvent);
                    setChanged(false);
                    onSetWebHookUrl && onSetWebHookUrl(res.payload.trigger.customWebhookUrl)
                })
                .catch((err) => {
                    showError(err);
                    setLoading(false);
                });
        } else if (steps[steps.length - 1].id > expanded) {
            setExpanded(x.id + 1);
        } else if (x.id === steps[steps.length - 1].id) {
            if (!trigger) {
                setLoading(true);
                WorkflowApi.addAction(params.id, {
                    accountId: data.accountId,
                    appId: data.appId,
                    eventId: data.eventId,
                    mappingSource: data.mappingSource,
                    previousActionId: data.previousActionId,
                    id: !data.isNew ? data.workflowActionId : null
                }).then((res) => {
                    toast.success("Saved");
                    setExpanded(0);
                    setLoading(false);
                    handleChange("workflowActionId", res.payload.id)
                }).catch(err => {
                    setLoading(false);
                    showError(err)
                })
            } else {
                setExpanded(0);
            }


        }

    };
    return (
        <div className={classes.root}>
            {steps.map((x) => (
                !x.hide && (
                    <>
                        <div key={x.id} onClick={() => {
                            (x.done || steps[x.id - 2]?.done || x.id === 1) && setExpanded(x.id);
                            expanded === x.id && setExpanded(0)
                        }}>
                            <div className={classes.labelRoot}>
                                <span className={classes.label}>{translate(x.label)}</span>
                                <SuccessIcon done={x.done}/>
                            </div>
                        </div>
                        {expanded === x.id && <section
                            className={classes.open}
                        >
                            <x.component
                                data={data}
                                onDone={(type: StepsType) => onDone(type)}
                                stepType={x.label}
                                isTrigger={trigger}
                                isWebHook={trigger && isWebHook}
                                stepInfo={stepInfo}
                                handleChange={(name: any, value: any) => {
                                    handleChange(name, value);
                                    setChanged(true)
                                }
                                }
                            />
                            {(webHookUrl && isWebHook && !changed) && <AppField
                                sx={{mb: 2}}
                                name="webHookUrl"
                                className={classes.webHookUrl}
                                onClick={() => {
                                    navigator.clipboard.writeText(webHookUrl).then(() => {
                                        toast.success("Copied to clipboard", {draggable: false})
                                    })
                                }}
                                disabled
                                title="Click to copy"
                                value={webHookUrl}
                            />}
                            {(!isWebHook || !webHookUrl || changed) && (
                                <AppButton
                                    disabled={!x.done || loading}
                                    loading={loading}
                                    fullWidth
                                    onClick={() => handleContinue(x)}
                                    variant="contained"
                                >
                                    {translate(
                                        (x.id === steps[steps.length - 1].id || isWebHook)
                                            ? `${trigger ? "FINISH" : "SAVE AND FINISH"}`
                                            : "CONTINUE"
                                    )}
                                </AppButton>
                            )}
                        </section>}
                    </>
                )
            ))}
        </div>
    );
}
