import React, {useCallback, useEffect, useState} from "react";
import WorkflowAppSelector from "./WorkflowAppSelector";
import {StepsType} from "./workflowEditor";
import {deepUpdate} from "immupdate";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {useParams} from "react-router";
import {useWorkflowContext} from "../../api/workflow/WorkflowContext";
import {WorkflowAccountSelector} from "./WorkflowAccountSelector";
import {WorkflowSteps} from "./WorkflowSteps";
import {TriggerProps} from "../../api/trigger/TriggerDTO";
import {useIntegrationsContext} from "../../api/integrations/IntegrationsContext";
import {WorkflowSaveAndTest} from "./WorkflowSaveAndTest";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        maxWidth: theme.typography.pxToRem(443),
        minWidth: theme.typography.pxToRem(300),
        width: "100%",
        background: "#fff",
        boxShadow: "0px 14px 24px #C6C8D7",
        borderRadius: "16px",
    },
}));

interface Props {
    readonly classesMore: any,
}

function WorkflowTrigger({classesMore}: Props) {
    const classes = useStyles();
    const {IntegrationsApi} = useIntegrationsContext();
    const {WorkflowApi} = useWorkflowContext();
    const [webHookUrl, setWebHookUrl] = useState<string | null>(null);
    const [triggerInfo, setTriggerInfo] = useState({
        eventName: "Trigger Name",
        appName: "Application Name",
        logo: "https://reactjs.org/favicon.ico"
    });
    const params: any = useParams();
    const [trigger, setTrigger] = useState<TriggerProps>({
        accountId: null,
        appId: null,
        eventId: null,
        inputData: {},
    });

    const [steps, setSteps] = useState([
        {
            label: StepsType.ChooseAppEndEvent,
            id: 1,
            done: false,
            hide: false,
            component: WorkflowAppSelector,
        },
        {
            label: StepsType.ChooseAccount,
            id: 2,
            done: false,
            hide: false,
            component: WorkflowAccountSelector,
        },
        // Todo | Trigger Setup
        {
            label: StepsType.TestTrigger,
            id: 3,
            done: false,
            hide: false,
            component: WorkflowSaveAndTest,
        },
    ]);

    const getStepInfo = (id: typeof trigger.appId) => {
        IntegrationsApi.getIntegrationById({params: {id: id}}).then(res => {
            setTriggerInfo({
                appName: res.payload.name,
                eventName: res.payload.description,
                logo: res.payload.logoUrl ?? "https://reactjs.org/favicon.ico"
            })
        })
    };

    const onDone = (type: StepsType) => {
        setSteps(prev => {
            return deepUpdate(prev)
                .at(steps.findIndex((x) => x.label === type))
                .abortIfUndef()
                .at("done")
                .modify(() => true)
        });
    };

    const onChangeVisible = (type: StepsType, value: boolean) => {
        setSteps(prev => {
            return deepUpdate(prev)
                .at(steps.findIndex((x) => x.label === type))
                .abortIfUndef()
                .at("hide")
                .modify(() => value)
        });
    }

    useEffect(() => {
        WorkflowApi.getWorkflowDetails(params.id)
            .then((res) => {
                let data = res.payload;
                if (Object.keys(data.trigger).length > 1) {
                    setTrigger({
                        accountId: data.trigger?.account?.id,
                        appId: data.trigger?.app?.id,
                        eventId: data.trigger?.event?.id,
                        inputData: {},
                    });
                    if (data.trigger?.app?.id) {
                        getStepInfo(data.trigger.app.id);
                        if (data.trigger.app.id === 10019) {
                            onChangeVisible(StepsType.ChooseAccount, data.trigger.app.id === 10019);
                            onChangeVisible(StepsType.TestTrigger, data.trigger.app.id === 10019);
                            setWebHookUrl(data.trigger.customWebhookUrl)
                        }
                    }
                    if (data.trigger?.app?.id && data.trigger?.event?.id) {
                        onDone(StepsType.ChooseAppEndEvent);
                    }
                    if (data.trigger?.account?.id) {
                        onDone(StepsType.ChooseAccount);
                    }
                }
            })
    }, [WorkflowApi, params.id]);

    const handleTriggerChange = useCallback((name: any, value: any) => {
        setTrigger((t) => ({
            ...t,
            //@ts-ignore
            [name]: value,
        }));
        if (name === "appId") {
            getStepInfo(value);
            let newSteps: typeof steps = [];
            if (value === 10019) {
                onChangeVisible(StepsType.ChooseAccount, true);
                onChangeVisible(StepsType.TestTrigger, true);
            } else {
                steps.forEach((item) => {
                    const newItem = item;
                    newItem.done = false;
                    newItem.hide = false;
                    newSteps.push(newItem)
                });
                setSteps(newSteps)
            }

        }
    }, []);

    return (
        <>
            <div className={classes.root}>
                <div className={classesMore.triggerHeader}>
                    <div className={classesMore.triggerHeaderTitleRoot}>
                        <div className={classesMore.triggerLogo}>
                            <img src={triggerInfo.logo} alt="logo"/>
                        </div>
                        <div className={classesMore.triggerInfo}>
                            <p className={classesMore.triggerName}>{triggerInfo.eventName}</p>
                            <h2 className={classesMore.triggerAppName}>{triggerInfo.appName}</h2>
                        </div>
                    </div>
                </div>
                <WorkflowSteps
                    trigger
                    webHookUrl={webHookUrl}
                    data={trigger}
                    steps={steps}
                    onSetWebHookUrl={(url: string) => setWebHookUrl(url) }
                    onChangeVisible={(type: StepsType, value: boolean) => onChangeVisible(type,value)}
                    stepInfo={triggerInfo}
                    onDone={(type: StepsType) => onDone(type)}
                    handleChange={(name: any, value: any) =>
                        handleTriggerChange(name, value)
                    }
                />
            </div>
        </>
    );
}

export default WorkflowTrigger;