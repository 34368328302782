import React, {useEffect, useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {TextField} from "@mui/material";
import {SelectOptions} from "../../constants/AppConstants";


interface Props {
    readonly options: SelectOptions[],
    readonly label?: string
    readonly loadingOptions?: boolean
    readonly onValueChange: (value: string) => void
    readonly [key: string]: any
}

export function AppAutoComplete({options,label,onValueChange,loadingOptions, ...props}: Props) {
    const [value, setValue] = React.useState<SelectOptions>({label: "", value: ""});
    const [inputValue, setInputValue] = React.useState('');
    const [changed, setChanged] = useState(false);
    const currentOption:SelectOptions = options?.filter((item:SelectOptions) => item.value === props.value)[0];
    return (
        <Autocomplete
            {...props}
            value={value.value ? value : currentOption}
            onChange={(event, newValue) => {
                if (newValue) {
                    setValue(newValue as SelectOptions);
                    //@ts-ignore
                    onValueChange(newValue.value)
                }
            }}
            disabled={loadingOptions}
            inputValue={changed ? inputValue : (currentOption?.label ?? inputValue)}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            id="controllable-states-demo"
            options={options}
            renderInput={(params) => <TextField onInput={() => setChanged(true)} {...params} label={loadingOptions ? "Loading..." : label} />}
        />
    );
}

