import React from 'react';
import {Box, FormControl, MenuItem, Select} from "@mui/material";
import {useShallowEqualSelector} from "../../hooks/useShallowSelector";
import {appLanguageSelector, switchLanguage} from "../../reducers/appReducer";
import {useDispatch} from "react-redux";

const languages = [
    {
        label: "English",
        value: "en",
    },
    {
        label: "Русский",
        value: "ru"
    }
];


function AppLanguageSwitcher() {
    const language = useShallowEqualSelector(appLanguageSelector);
    const dispatch = useDispatch();
    const onSelect = (value: any) => dispatch(switchLanguage({language: value}));

    return (
        <Box sx={{minWidth: 120}}>
            <FormControl fullWidth>
                <Select
                    onChange={(e) => onSelect(e.target.value)}
                    defaultValue={language}
                >
                    {languages.map(item => {
                        return (
                            <MenuItem key={item.value}  value={item.value}>{item.label}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </Box>
    );
}

export default AppLanguageSwitcher;