import {BaseApi, Options} from "../BaseApi";

export class TriggerApi extends BaseApi {
    public getEventList(url: string, id?: number): Promise<any> {
        return this.get(url, {params: {integrationId: id}})
    }

    public getTriggerDetails(options?: Options): Promise<any> {
        return this.get("/trigger/{id}", options)
    }
}