import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Alert, InputAdornment, Theme } from "@mui/material";
import { AppField } from "../ui/AppField";
import SearchIcon from "@mui/icons-material/Search";
import { useIntegrationsContext } from "../../api/integrations/IntegrationsContext";
import { useI18n } from "../../i18n/I18nContext";
import cx from "classnames";
import { StepsType } from "./workflowEditor";
import { useTriggerContext } from "../../api/trigger/TriggerContext";
import { AppSelect } from "../ui/AppSelect";
import Modal from "../ui/Modal";
import { Loader } from "../ui/Loader";
import {showError} from "../../utils/NotificationUtils";
import {AppAutoComplete} from "../ui/AppAutoComplete";
import {log} from "util";

interface styleProps {
    readonly withTitle?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    integrationsList: {
        display: "flex",
        flexWrap: "wrap",
        gap: theme.typography.pxToRem(16),
        margin: `${theme.typography.pxToRem(16)} 0`,
    },
    appItem: {
        padding: `0 ${theme.typography.pxToRem(4)}`,
        height: theme.typography.pxToRem(32),
        border: "1px solid #E5E5E5",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        transition: "0.3s",
        "&:hover": {
            borderColor: theme.palette.primary.main,
        },
    },
    appLogo: {
        width: theme.typography.pxToRem(24),
        height: theme.typography.pxToRem(24),
        "&.withTitle": {
            marginRight: theme.typography.pxToRem(6),
        },
        marginRight: (props: styleProps) =>
            theme.typography.pxToRem(props.withTitle ? 6 : 0),
        "& img": {
            objectFit: "contain",
            width: "100%",
            height: "100%",
        },
    },
    currentApp: {
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
        overflow: "hidden",
        position: "relative",
        width: "100%",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "8px",
        padding: "8px 10px",
        cursor: "pointer",
        transition: "0.2s",
        "&:before": {
            content: '""',
            width: "40px",
            height: "100%",
            background: "linear-gradient(to left, #fff, transparent)",
            position: "absolute",
            right: 0,
            top: 0,
        },
        "&:hover": {
            borderColor: theme.palette.primary.main,
            transform: "scale(0.95)",
            "& .changeBtn": {
                opacity: 1,
                pointerEvents: "all",
            },
        },
    },
    currentAppLogo: {
        margin: `0 ${theme.typography.pxToRem(6)} !important`,
        minWidth: theme.typography.pxToRem(24),
        minHeight: theme.typography.pxToRem(24),
    },
    changeAppBtn: {
        position: "absolute",
        width: "100%",
        height: "100%",
        background: theme.palette.primary.main,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.common.white,
        top: 0,
        left: 0,
        opacity: 0,
        pointerEvents: "none",
        transition: "0.2s",
        zIndex: 10,
    },
    changeAppLink: {
        color: "#2569ff",
        cursor: "pointer",
    },
}));

interface Props {
    readonly handleChange: (name: any, value: any) => void;
    readonly stepType: StepsType;
    readonly onDone: (type: StepsType) => void;
    readonly data: any;
    readonly stepInfo: any;
    readonly isTrigger?: boolean;
}

function WorkflowAppSelector({
                                 handleChange,
                                 onDone,
                                 stepType,
                                 data,
                                 stepInfo,
                                 isTrigger,
                             }: Props) {
    const { TriggerApi } = useTriggerContext();
    const [searchText, setSearchText] = useState("");
    const classes = useStyles({ withTitle: searchText.length > 0 });
    const { IntegrationsApi } = useIntegrationsContext();
    const [eventData, setEventData] = useState<any>();
    const [integrations, setIntegrations] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { translate } = useI18n();
    useEffect(() => {
        setLoading(true);
        IntegrationsApi.getIntegrationsList().then((res) => {
            setIntegrations(res.payload.list);
            setLoading(false);
        }).catch(err => {
            showError(err);
        });
    }, [IntegrationsApi]);

    useEffect(() => {
        if (data?.appId) {
            TriggerApi.getEventList(
                `/${isTrigger ? "trigger" : "action"}/list/:integrationId`,
                data?.appId
            ).then((res) => {
                let data: any = [];
                res.payload?.list?.map((x: any) =>
                    data.push({ label: x.name, value: x.id })
                );
                setEventData(data);
            });
        }
    }, [data, TriggerApi]);

    return (
        <>
            <div className={classes.root}>
                {!data.appId && (
                    <AppField
                        onChange={(e: any) => setSearchText(e.target.value)}
                        icon={SearchIcon}
                        placeholder="Search"
                        name="Search"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                )}

                <ul className={classes.integrationsList}>
                    {loading ? (
                        <Loader />
                    ) : integrations && integrations?.length > 0 ? (
                        <>
                            {data?.appId &&
                            (integrations.filter((x: any) => x.id === data?.appId)
                                .length ? (
                                integrations
                                    .filter((x: any) => x.id === data?.appId)
                                    .map((item: any) => {
                                        return (
                                            <li
                                                title={item.name}
                                                className={classes.currentApp}
                                                key={item.id}
                                            >
                                                {translate("CURRENT_APP")}:{" "}
                                                <div
                                                    className={cx(
                                                        classes.appLogo,
                                                        classes.currentAppLogo
                                                    )}
                                                >
                                                    <img src={stepInfo.logo} alt="" />
                                                </div>
                                                <span>{item.name}</span>
                                                <div
                                                    className={cx(classes.changeAppBtn, "changeBtn")}
                                                    onClick={() => setShowModal(true)}
                                                >
                                                    {translate("CHANGE_APP")}
                                                </div>
                                            </li>
                                        );
                                    })
                            ) : (
                                <Alert style={{ width: "100%" }} severity="error">
                                    App with id {data?.appId} not found |{" "}
                                    <span
                                        onClick={() => setShowModal(true)}
                                        className={classes.changeAppLink}
                                    >
                      {translate("CHANGE_APP")}
                    </span>
                                </Alert>
                            ))}

                            {!data?.appId &&
                            integrations?.map((item: any) => {
                                return (
                                    item.name
                                        .toLowerCase()
                                        .includes(searchText.toLowerCase()) && (
                                        <li
                                            title={item.name}
                                            key={item.id}
                                            className={classes.appItem}
                                            onClick={() => {
                                                handleChange("appId", item.id);
                                            }}
                                        >
                                            <div className={classes.appLogo}>
                                                <img src={item.logoUrl} alt="" />
                                            </div>
                                            <span>{searchText.length > 0 && item.name}</span>
                                        </li>
                                    )
                                );
                            })}
                        </>
                    ) : (
                        <h1>Empty</h1>
                    )}
                </ul>
                <div>
                    {data?.appId && (
                        <div>
                            <AppAutoComplete
                                sx={{ mb: 2 }}
                                label={
                                    isTrigger
                                        ? translate("CHOOSE_TRIGGER_EVENT")
                                        : translate("CHOOSE_ACTION_EVENT")
                                }
                                options={eventData}
                                value={data?.eventId}
                                required
                                onValueChange={(value) => {
                                    handleChange("eventId", value);
                                    onDone(stepType);
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
            <Modal show={showModal} onClose={() => setShowModal(false)}>
                <AppField
                    onChange={(e: any) => setSearchText(e.target.value)}
                    icon={SearchIcon}
                    placeholder="Search"
                    name="Search"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <ul className={classes.integrationsList}>
                    {integrations ? (
                        integrations?.map((item: any) => {
                            return (
                                item.name.toLowerCase().includes(searchText.toLowerCase()) &&
                                data?.appId !== item.id && (
                                    <li
                                        title={item.name}
                                        key={item.id}
                                        className={classes.appItem}
                                        onClick={() => {
                                            handleChange("appId", item.id);
                                            handleChange("eventId", null);
                                            handleChange("accountId", null);
                                            handleChange("mappingSource", null);
                                            setShowModal(false);
                                        }}
                                    >
                                        <div className={cx(classes.appLogo, "withTitle")}>
                                            <img src={item.logoUrl} alt="" />
                                        </div>
                                        <span>{item.name}</span>
                                    </li>
                                )
                            );
                        })
                    ) : (
                        <p>{translate("EMPTY")}</p>
                    )}
                </ul>
            </Modal>
        </>
    );
}

export default WorkflowAppSelector;
