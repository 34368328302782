import {BaseApi, Options} from "../BaseApi"
import {GetWorkflowListProps, UpdateWorkflowProps} from "./WorkflowDTO";


export class WorkflowApi extends BaseApi{
    public createWorkflow(options?: Options): Promise<any> {
        return this.post("/workflow/create", options)
    }

    public getWorkflowDetails(id?: number): Promise<any> {
        return this.get("/workflow/:id", {params: {id: id}})
    }

    public activateWorkflow(options?: Options): Promise<any> {
        return this.patch("/workflow/activate", options)
    }

    public deActivateWorkflow(options?: Options): Promise<any> {
        return this.patch("/workflow/deactivate", options)
    }

    public deleteWorkflow(options?: Options): Promise<any> {
        return this.delete("/workflow/:workflowId", options)
    }

    public configureTrigger(id: number, body: any): Promise<any> {
        return this.patch(`workflow/${id}/trigger/configure`, {data: body})
    }

    public addAction(id: number, body: any): Promise<any> {
        return this.patch(`workflow/${id}/action/add`, {data: body})
    }

    public testTrigger(id: number): Promise<any> {
        return this.get(`/workflow/${id}/test/trigger`)
    }

    public getWorkflowList(): Promise<GetWorkflowListProps[]> {
        return this.get("/workflow/list")
    }

    public updateWorkflow(body?: UpdateWorkflowProps) {
        return this.put("/workflow/update", {data: body})
    }
}