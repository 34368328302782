import React from "react";
import {TextField, Theme} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        "& .MuiInputAdornment-root": {
            margin: 0
        },
        "& .MuiOutlinedInput-input": {
            fontSize: theme.typography.pxToRem(14),
            padding: theme.typography.pxToRem(12),
        },
        "& .MuiOutlinedInput-root ": {
            borderRadius: theme.typography.pxToRem(8),
        },
    },
    label: {
        marginBottom: theme.typography.pxToRem(8),
        fontSize: theme.typography.pxToRem(14),
        display:"block",
        textTransform:"capitalize",
        fontWeight: 500
    },
}));

interface Props {
    readonly placeholder?: string;
    readonly name: string;
    readonly labelText?: string

    readonly [key: string]: any
}

export function AppField({placeholder, name, icon, labelText, ...props}: Props) {
    const classes = useStyles();
    return (
        // @ts-ignore
        <div className={props.rootClassName}>
            {labelText && <label className={classes.label}>{labelText}</label>}
            <TextField
                fullWidth
                placeholder={placeholder}
                className={classes.root}
                id={name}
                name={name}
                {...props}
                variant="outlined"

            />
        </div>
    );
}
