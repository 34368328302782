import {useCallback, useMemo} from "react";

import {AuthApi} from "./AuthApi";
import {useApiBase} from "../ApiContext";
import {useDispatch} from "react-redux";
import {changeViewMode} from "../../reducers/appReducer";
import {ViewMode} from "../dto/AppDTO";
import Cookies from "js-cookie";
import {useHistory} from "react-router";

export interface AuthContextProps {
  readonly AuthApi: AuthApi;
  readonly logout: () => void;
}

export function useAuthContext(): AuthContextProps {
  const data = useApiBase();
  const dispatch = useDispatch();
  const history = useHistory();
  const api = useMemo(() => new AuthApi(data), [data]);

  const logoutHandler = useCallback(() => {
    Cookies.remove("token");
    dispatch(changeViewMode({ viewMode: ViewMode.Undetermined }));
    history.replace({pathname: "/auth/login"})
  }, [dispatch]);


  return {
    AuthApi: api,
    logout: logoutHandler,
  };
}
