import React, {useMemo} from 'react';
import {Provider} from "react-redux";
import {configureStore} from "../store/configureStore";
import {PersistGate} from "redux-persist/integration/react";
import {BrowserRouter, Route} from "react-router-dom"
import {ToastContainer} from "react-toastify";
import {RootContainer} from "./RootContainer";
import ProviderContainer from "./ProviderContainer";
import {responsiveFontSizes} from "@mui/material";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import 'react-toastify/dist/ReactToastify.css';

import "../styles/global.css"

export const theme = responsiveFontSizes(
    createTheme({
        palette: {
            primary: {
                main: "#3F51B5",
                light: "#bcffbc",
                dark: "#003800",
                contrastText: "#fff",
            },
            info: {
                main: "#00ade3",
                light: "#FEF5F5",
                dark: "#00ade3",
                contrastText: "#FFA500",
            },
            divider: "#E8E8E8",
            text: {
                primary: "#3F51B5",
                secondary: "#0B1A6A",
                hint: "#B5B5B5",
            },
            common: {
                transparent: "rgba(0, 0, 0, 0)",
                white: "#ffffff",
            },
            background: {
                default: "#F6F7FC",
            },
            grey: {
                200: "#E8E8E8",
                300: "#F2F2F2",
                500: "#D0D4EB",
                700: "#B5B5B5",
                A100: "#757575"
            },
            action: {
                hover: "#fbfbfb",
            },
            success: {
                main: "#4ABC55",
                // light: "#D2F7D2",
                // dark: "#EFF1F5",
                // contrastText: "#E5E7ED",
            },
        },
        typography: {
            //fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        },
    }),
);

theme.shadows[1] = "0px 14px 24px #C6C8D7";

export function AppContainer() {
    const store = useMemo(() => configureStore(), []);

    if (!store) {
        return null;
    }
    return (
        <Provider store={store.store}>
            <ThemeProvider theme={theme}>
                <PersistGate persistor={store.persistor}>
                    <ProviderContainer>
                        <BrowserRouter>
                            <>
                                <Route component={RootContainer}/>
                                <ToastContainer/>
                            </>
                        </BrowserRouter>
                    </ProviderContainer>
                </PersistGate>
            </ThemeProvider>
        </Provider>
    );
}

